@import "../styles/variables";
@import "../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.bundle-detail-page {
  background-color: $cream-color;
  padding: 2rem 0;
  position: relative;

  // Give global vertical spacing between sections
  > *:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .above-the-fold {
    @include container;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    background-color: white;
    @include box-shadow(1);
    border-radius: 0.5rem;
    align-items: flex-start;
    padding: 2rem;
    margin-bottom: 2rem;

    .left-column {
      flex: none;
      position: sticky;
      top: 2rem;
      padding: 2rem;
      max-width: 500px;
      width: 500px;
      box-sizing: border-box;
    }

    .right-column {
      flex: 1;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      h1 {
        font-size: 1.75rem;
        font-family: $font-primary;
        margin-bottom: 0.5rem;
        color: $dark-purple;
      }

      .price-breakdown {
        font-weight: 600;
        color: $very-light-gray;
      }

      .bundle-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1.5rem 0;

        button {
          @include button-styles($dominant-purple, white);
          flex: 1 1 auto;
          min-width: 150px;
        }
      }

      .live-stats-counter {
        margin: 1rem 0;
        font-size: 0.9rem;
        color: $very-light-gray;
      }
    }
  }

  .trust-zone{
    @include container;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: white;
    @include box-shadow(1);
  }

  .product-details-section {
    @include container;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: white;
    margin-top: 2rem;
    @include box-shadow(1);
  }

  .faqs-licensing {
    @include container;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-unit;

    .faqs-column {
      flex: 1 1 100%;
      padding: $spacing-unit;
      background-color: white;
      border-radius: 0.5rem;
      @include box-shadow(1);
    }
  }

  .upsells-section {
    @include container;
    display: flex;
    flex-direction: column;
    gap: $spacing-unit;

    .upsell-row {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-unit;

      > * {
        flex: 1 1 calc(50% - #{$spacing-unit});
      }
    }
  }

  @include respond-to("small") {
    .above-the-fold {
      flex-direction: column;

      .left-column {
        position: static;
        max-width: 100%;
        padding: 1rem;
      }

      .right-column {
        padding: 1rem;
      }
    }

    .faqs-licensing {
      flex-direction: column;

      .faqs-column {
        flex: 1 1 100%;
      }
    }

    .upsells-section {
      .upsell-row {
        flex-direction: column;
      }
    }
  }
}
