@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.product-highlights {
  @include container;
  margin: $spacing-unit * 2 0;
  padding: $spacing-unit * 1.5;
  background: white;
  border-radius: 0.5rem;
  @include box-shadow(1);

  .highlights-title {
    color: $dominant-purple;
    font-family: $font-primary;
    text-align: center;
    margin-bottom: $spacing-unit * 1.5;
    @include responsive-font-size(1.5rem, 1.7rem, 1.8rem);
  }

  .highlights-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $spacing-unit;
    list-style: none;
    padding: 0;
  }

  .highlight-card {
    @include flex-center;
    flex-direction: column;
    padding: $spacing-unit;
    background: $very-light-gray;
    border-radius: 0.5rem;
    text-align: center;
    @include transition(all);
    border: 1px solid transparent;

    &:hover {
      transform: translateY(-3px);
      border-color: rgba($light-purple, 0.3);
      @include box-shadow(2);
    }
  }

  .highlight-icon {
    @include flex-center;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(45deg, $light-purple, $dominant-purple);
    border-radius: 50%;
    color: white;
    margin-bottom: $spacing-unit;
    font-size: 1.5rem;
    @include transition(all);

    svg {
      filter: drop-shadow(0 2px 2px rgba($charcoal, 0.1));
    }
  }

  .highlight-text {
    color: $dark-purple;
    font-family: $font-paragraph;
    margin: 0;
    line-height: 1.5;
    @include responsive-font-size(1rem, 1.05rem, 1.1rem);
  }

  @include media-breakpoint-down(md) {
    padding: $spacing-unit;

    .highlights-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .highlight-card {
      padding: $spacing-unit-small;
    }
  }

  @include media-breakpoint-down(sm) {
    .highlights-grid {
      grid-template-columns: 1fr;
    }

    .highlight-icon {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.2rem;
    }
  }
}
