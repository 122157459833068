@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.modal-content {
  border-radius: 0.5rem;
  padding: $spacing-unit * 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.modal-header {
  border-bottom: none;
  .modal-title {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit;
  }
  .close {
    font-size: 1.5rem;
    color: $dark-purple;
    &:hover {
      color: $dominant-purple;
    }
  }
}

.modal-body {
  p {
    font-family: $font-paragraph;
    color: $dark-purple;
    margin-bottom: $spacing-unit-small;
  }
}

.modal-footer {
  border-top: none;
}

.btn-primary {
  @include button-styles($dominant-purple, white);
  width: 100%;
  margin-bottom: $spacing-unit;

  &:hover {
    background-color: darken($dominant-purple, 10%);
  }
}

.btn-secondary {
  @include button-styles($light-purple, white);
  width: 100%;
  margin-bottom: $spacing-unit;

  &:hover {
    background-color: darken($light-purple, 10%);
  }
}

.btn-outline-primary {
  @include button-styles(white, $dominant-purple);
  width: 100%;
  margin-bottom: $spacing-unit;
  border: 1px solid $dominant-purple;

  &:hover {
    background-color: $dominant-purple;
    color: white;
  }
}
