@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.sidebar {
  padding: $spacing-unit * 2;
  background-color: $very-light-gray;
  border-radius: 0.25rem;
  @include box-shadow(1);

  h3 {
    font-family: $font-navigation;
    color: $deep-blue;
    font-size: 1.25rem;
    margin-bottom: $spacing-unit;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: $spacing-unit / 2;
      font-family: $font-primary;
      color: $dark-purple;

      a {
        color: inherit;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: $rich-gold;
        }
      }
    }
  }
}
