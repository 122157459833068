@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.cart-review {
  @include container;
  background-color: white;
  padding: $spacing-unit * 2;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  &-title {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit;
    text-align: center;
  }

  .cart-items {
    display: flex;
    flex-direction: column;
    gap: $spacing-unit-small;

    .cart-item {
      display: flex;
      align-items: center;
      padding: $spacing-unit-small;
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }

      &-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: $spacing-unit-small;
      }

      &-info {
        display: flex;
        flex-direction: column;

        &-name {
          font-family: $font-secondary;
          color: $dark-purple;
        }

        &-price {
          color: $dark-purple;
        }
      }
    }
  }

  .cart-review-form {
    margin-top: $spacing-unit;

    label {
      display: block;
      font-family: $font-secondary;
      color: $dark-purple;
      margin-bottom: $spacing-unit-small;
    }

    input {
      @include text-truncate;
      padding: $spacing-unit-small;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      width: 100%;
      margin-bottom: $spacing-unit-small;

      &:focus {
        border-color: $light-purple;
        outline: none;
      }
    }
  }

  .next-step-button {
    @include button-styles($dominant-purple, white);
    width: 100%;
    margin-top: $spacing-unit;
  }
}
