@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

@keyframes subtleHeartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.01);
  }
  50% {
    transform: scale(1.02);
  }
  75% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

.welcome-message {
  background-color: $cream-color;
  padding: $spacing-unit;
  border: 1px solid $light-gray;
  border-radius: 0.25rem;
  text-align: center;
  margin-bottom: $spacing-unit;
  animation: subtleHeartbeat 4s infinite; // Slower and more subtle

  h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: 0.5rem;
  }

  p {
    font-family: $font-secondary;
    color: $dark-purple;
    font-size: 1em;
  }

  @include respond-to("small") {
    padding: $spacing-unit-small;
  }
}
