@import "../../styles/variables";
@import "../../styles/mixins";

.custom-navbar {
  background-color: $very-light-gray;
  @include elevation(1);
  position: relative;
  z-index: 100;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: transform 0.4s ease, box-shadow 0.3s ease;
  will-change: transform;

  &.scroll-up {
    transform: translateY(0);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05); // subtle drop shadow when revealed
  }

  &.scroll-down {
    transform: translateY(-100%);
    box-shadow: none;
  }

  /* --- Top Section: Logo + Utility Icons --- */
  .navbar-top {
    @include flex-center;
    justify-content: space-between;
    padding: $spacing-unit;
    border-bottom: 1px solid $light-gray;

    .brand-logo img {
      height: 60px;
      @include respond-to("small") { height: 60px; }
    }

    .utility-icons {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon-link {
        position: relative;
        font-size: 1.25rem;
        color: $dark-purple;
        @include transition(all, 0.2s);

        &:hover { color: $dominant-purple; }

        .badge {
          position: absolute;
          top: -5px;
          right: -10px;
          background-color: $rich-gold;
          color: white;
          font-size: 0.75rem;
          @include circle(20px);
          @include flex-center;
        }
      }

      .mobile-menu-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: $dark-purple;
        cursor: pointer;
        display: none;

        @include respond-to("small") { display: block; }
      }
    }
  }

  /* --- Desktop Layout (2-Column Approach) --- */
  .desktop-only {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 2rem;
    padding: var(--nav-link-spacing);
    border-bottom: 1px solid $light-gray;

    @include respond-to("small") { display: none; }

    .navbar-middle {
      display: contents; // Modern grid content distribution

      .user-auth {
        display: flex;
        gap: 1rem;
        align-items: center;

        .logged-in {
          display: flex;
          align-items: center;
          gap: 1rem;
          position: relative;

          &:hover .auth-dropdown {
            opacity: 1;
            visibility: visible;
          }
        }

        .auth-dropdown {
          position: absolute;
          top: 100%;
          right: 0;
          background: $very-light-gray;
          border: 1px solid $light-gray;
          border-radius: 4px;
          padding: 0.5rem;
          opacity: 0;
          visibility: hidden;
          @include transition(all, var(--transition-timing));

          button, a {
            white-space: nowrap;
            display: block;
            width: 100%;
            text-align: left;
          }
        }
      }

      .search-container {
        width: var(--search-width);
        justify-self: center;
        
        .search-form {
          display: flex;
          border: 1px solid $light-gray;
          border-radius: 4px;
          overflow: hidden;

          .search-input {
            flex: 1;
            padding: 0.5rem;
            border: none;
            &:focus { outline: none; }
          }

          .search-button {
            padding: 0.5rem 1rem;
            background-color: $dominant-purple;
            color: white;
            border: none;
            cursor: pointer;
            @include transition(all, 0.2s);
            &:hover { background-color: darken($dominant-purple, 10%); }
          }
        }
      }
    }

    /* Navigation Links */
    nav.navbar-bottom {
      .nav-links {
        display: flex;
        gap: clamp(1rem, 2vw, 2rem);
        margin: 0;
        padding: 0;
        list-style: none;

        li a {
          color: $dark-purple;
          text-decoration: none;
          @include transition(all, 0.2s);
          &:hover { color: $dominant-purple; }
        }
      }
    }
  }
  /* 
    Side Menu for Mobile 
    ====================
    - Off-canvas menu slides in from the right on small screens.
    - Contains user/auth, search, and nav links in one place.
  */
  .side-menu {
    // Hidden on desktop
    display: none;
    @include respond-to("small") {
      display: block;
    }

    /* Off-canvas positioning */
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background-color: $very-light-gray;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9999;

    /* Slide in when .open is added */
    &.open {
      transform: translateX(0);
    }

    /* Close button (optional, if you want a second close icon inside the panel) */
    .close-btn {
      background: none;
      border: none;
      font-size: 1.5rem;
      color: $dark-purple;
      cursor: pointer;
      padding: $spacing-unit;
      float: right;
    }

    /* 
      Spacing for each section inside the side menu
      You can group or reorder these as you like 
    */
    .user-auth-mobile,
    .search-container-mobile,
    .nav-links-mobile {
      margin: $spacing-unit;
    }

    /* User/Auth on mobile */
    .user-auth-mobile {
      .logged-in {
        span {
          font-size: 1rem;
          color: $dark-purple;
        }
        a,
        button {
          display: inline-block;
          margin-right: 1rem;
          font-size: 0.9rem;
          text-decoration: underline;
          color: $light-purple;
          @include transition(all, 0.2s);

          &:hover {
            color: $dominant-purple;
          }
        }
      }

      .auth-links {
        a {
          margin-right: 1rem;
          font-size: 0.9rem;
          text-decoration: underline;
          color: $light-purple;
          @include transition(all, 0.2s);

          &:hover {
            color: $dominant-purple;
          }
        }
      }
    }

    /* Search container on mobile */
    .search-container-mobile {
      .search-form {
        display: flex;
        border: 1px solid $light-gray;
        border-radius: 4px;
        overflow: hidden;

        .search-input {
          flex: 1;
          padding: 0.5rem;
          border: none;
          &:focus {
            outline: none;
          }
        }

        .search-button {
          padding: 0.5rem;
          background-color: $dominant-purple;
          color: #fff;
          border: none;
          cursor: pointer;
          @include transition(all, 0.2s);

          &:hover {
            background-color: darken($dominant-purple, 10%);
          }
        }
      }
    }

    /* Navigation links on mobile */
    .nav-links-mobile {
      list-style: none;
      padding: 0;
      margin-top: $spacing-unit;

      li {
        margin-bottom: $spacing-unit-small;

        a {
          color: $dark-purple;
          text-decoration: none;
          font-size: 1rem;
          @include transition(all, 0.2s);

          &:hover {
            color: $dominant-purple;
          }
        }
      }
    }
  }
}