@import "../styles/variables";
@import "../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.product-detail-page {
  background-color: $very-light-gray; // or white if you prefer
  padding: 2rem 0;
  position: relative;

  /* ==================================================
     ABOVE-THE-FOLD (High-Impact Zone)
     Merges your .product-main-section logic with the "above-the-fold" concept
  ================================================== */
  .above-the-fold {
    @include container;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    background-color: white;
    @include box-shadow(1);
    border-radius: 0.5rem;
    align-items: flex-start;

    /* LEFT COLUMN: Sticky Image Gallery */
    .left-column {
      flex: none;
      position: sticky;
      top: 1rem;
      padding: 2rem;
      max-width: 500px;
      box-sizing: border-box;
      width: 500px;
    }

    /* RIGHT COLUMN: Product Info (Price, Reviews, ExpressCheckout, StickyBuyNow triggers) */
    .right-column {
      flex: 1;
      padding: 2rem 2rem 2rem 0;
      height: fit-content;

      h1 {
        font-size: 1.5rem;
        font-family: $font-primary;
        margin-bottom: 1rem;
        color: $dark-purple;
      }

      .price-breakdown {
        margin-bottom: 1rem;
        .original-price {
          font-size: 1rem;
          color: $light-gray;
          .strike-through {
            text-decoration: line-through;
          }
        }
        .current-price {
          font-size: 2rem;
          font-weight: bold;
          color: $rich-gold;
        }
        // .savings-highlight {
        //   font-size: 1.2rem;
        //   color: $teal;
        //   margin-top: 0.5rem;
        // }
        // .trust-badges {
        //   margin-top: 1rem;
        //   .badge {
        //     display: inline-block;
        //     background-color: $soft-yellow;
        //     color: $dark-purple;
        //     padding: 0.25rem 0.5rem;
        //     border-radius: 0.25rem;
        //     margin-right: 0.5rem;
        //     font-size: 0.9rem;
        //     @include transition(background-color);
        //     &:hover {
        //       background-color: lighten($soft-yellow, 10%);
        //     }
        //   }
        // }
      }

      .reviews-summary {
        margin-bottom: 1rem;
        .star-icon {
          color: $rich-gold;
          margin-right: 0.25rem;
        }
      }

      .product-buttons {
        margin-bottom: 1rem;
        button {
          @include button-styles($dominant-purple, white);
          margin-right: 1rem;
          @include transition(background-color);
        }
      }

      // .express-checkout {
      //   margin-bottom: 1rem;
      //   .best-seller-badge {
      //     background-color: $rich-gold;
      //     color: white;
      //     font-size: 0.9rem;
      //     padding: 0.25rem 0.5rem;
      //     border-radius: 0.25rem;
      //     margin-bottom: 0.5rem;
      //     display: inline-block;
      //   }
      //   .express-checkout-button {
      //     @include button-styles($teal, white);
      //     font-size: 1.1rem;
      //     width: 100%;
      //     padding: 0.75rem;
      //     @include transition(background-color);
      //     &:hover {
      //       background-color: darken($teal, 10%);
      //     }
      //   }
      // }

      .live-stats-counter {
        margin-bottom: 1rem;
        margin-top: 1rem;
        // font-size: 1rem;
        // color: $charcoal;
      }

      // // If you have tabs for reviews/specs
      // .product-tabs {
      //   margin-top: 2rem;
      //   .nav-link {
      //     @include transition(color);
      //     &:hover,
      //     &.active {
      //       color: $dominant-purple;
      //       font-weight: bold;
      //     }
      //   }
      // }

      // .product-description {
      //   margin-top: 1rem;
      //   p {
      //     margin-bottom: 1rem;
      //     line-height: 1.6;
      //   }
      // }
    }
  }

  /* ==================================================
     SMART URGENCY & TRUST ZONE
     (CountdownTimer, ProductHighlights, etc.)
  ================================================== */
  .trust-zone {
    @include container;
    margin: 2rem auto;
    text-align: center;
    background-color: white;
    @include box-shadow(1);
    border-radius: 0.5rem;
    padding: 1rem;
  }

  /* ==================================================
     PRODUCT DESCRIPTION & BENEFITS (Adaptive Sections)
  ================================================== */
  .product-details-section {
    @include container;
    // margin: 2rem auto;
    // display: center;
  }

  // .product-tabs.custom-tabs {
  //   border-bottom: 2px solid $light-purple;

  //   .custom-nav-item {
  //     margin-right: 1rem;
  //   }

  //   .custom-nav-link {
  //     color: $dark-purple;
  //     padding: 1rem;
  //     font-size: 1rem;
  //     transition: color 0.3s ease;
  //     &:hover {
  //       color: $dominant-purple;
  //     }
  //     &.active {
  //       color: $dominant-purple;
  //       border-bottom: 2px solid $dominant-purple;
  //       font-weight: bold;
  //     }
  //   }
  // }

  // .custom-tab-content {
  //   padding: $spacing-unit;
  //   background-color: white;
  //   @include box-shadow(1);
  //   border-radius: 0.25rem;
  // }

  // .custom-tab-pane {
  //   // Additional styling for each tab pane if needed
  // }

  /* ==================================================
   SPECS & REVIEWS (WITH TABS)
================================================== */
  .specs-and-reviews-section {
    @include container;
    background-color: white;
    padding-top: 0.5rem;
    padding-bottom: 3rem; 
    border-top: 1px solid $light-gray;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  }

  .product-tabs {
    @include flex-center;
    justify-content: flex-start;
    border-bottom: 2px solid $very-light-gray;
    margin-bottom: 2rem;
    gap: 1rem;

    .custom-nav-item {
      .custom-nav-link {
        font-family: $font-navigation;
        font-weight: 600;
        color: $slate-gray;
        background: none;
        border: none;
        border-bottom: 3px solid transparent;
        padding: 0.75rem 1.5rem;
        border-radius: 0;
        @include transition(all, 0.25s);

        &:hover {
          color: $dominant-purple;
          background-color: $very-light-gray;
        }

        &.active {
          color: $dominant-purple;
          border-bottom: 3px solid $dominant-purple;
          background-color: white;
        }
      }
    }
  }

  .custom-tab-content {
    // padding-top: 0.2rem;

    .custom-tab-pane {
      animation: fadeIn 0.3s ease-in-out;
      // padding-top: 0.2rem;
      background-color: white;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // ==================================
  // 📱 Mobile Enhancements
  // ==================================

  @include respond-to("small") {
    .specs-and-reviews-section {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
      padding-bottom: 2rem;
    }

    .product-tabs {
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      gap: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $very-light-gray;

      .custom-nav-item {
        display: inline-block;

        .custom-nav-link {
          font-size: 0.9rem;
          padding: 0.5rem 1rem;
          white-space: nowrap;
          border-bottom-width: 2px;
        }
      }
    }

    .custom-tab-content {
      padding-top: 0.2rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }


   /* ==================================================
   SOCIAL PROOF & USER-GENERATED CONTENT
  ================================================== */

  .social-proof-section {
    @include container;
    margin: 2rem auto;
    background-color: white;
    border-radius: 0.5rem;
    @include box-shadow(1);
    padding: 2rem;
  
    h3 {
      text-align: center;
      margin-bottom: 1.5rem;
      font-family: $font-primary;
      color: $dominant-purple;
      @include responsive-font-size(1.4rem, 1.6rem, 1.8rem);
    }
  }
  
  @include respond-to("small") {
    .social-proof-section {
      padding: 1.5rem 1rem;
    }
  }
  


  /* ==================================================
     FAQs & LICENSING
  ================================================== */
  .faqs-licensing {
    @include container;
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-unit;
  
    .faqs-column,
    .licensing-column {
      flex: 1 1 48%;
      min-width: 300px;
      padding: $spacing-unit;
      background-color: white;
      border-radius: 0.5rem;
      @include box-shadow(1);
    }
  
    @include respond-to("small") {
      flex-direction: column;
  
      .faqs-column,
      .licensing-column {
        flex: 1 1 100%;
      }
    }
  }


  /* ==================================================
     ADDITIONAL OFFERS & UPSELLS
  ================================================== */
  .upsells-section {
    @include container;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  
    .upsell-carousel,
    .related-carousel {
      background-color: white;
      border-radius: 0.5rem;
      padding: 2rem 1rem;
      @include elevation(1);
    }
  
    .upsell-blocks {
      display: flex;
      gap: $spacing-unit;
      justify-content: space-between;
    
      > * {
        flex: 1 1 50%;
        min-width: 280px;
        background-color: white;
        border-radius: 1rem;
        padding: 2rem 1.5rem;
        position: relative;
        overflow: hidden;
        @include elevation(2);
        @include transition(transform);
    
        &:hover {
          transform: translateY(-4px);
          @include elevation(3);
        }
    
        // Optional Ribbon / Badge
        &::before {
          content: attr(data-badge);
          position: absolute;
          top: 1rem;
          right: -2rem;
          background: $dominant-purple;
          color: white;
          font-family: $font-action;
          font-size: 0.75rem;
          padding: 0.25rem 2rem;
          transform: rotate(45deg);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        }
    
        h4 {
          font-family: $font-primary;
          color: $dark-purple;
          font-size: 1.25rem;
          margin-bottom: 0.75rem;
        }
    
        p {
          font-family: $font-paragraph;
          color: $charcoal;
          font-size: 1rem;
          margin-bottom: 1rem;
        }
    
        .cta-button {
          @include button-styles($dominant-purple, white);
          font-size: 0.95rem;
          margin-top: auto;
        }
      }
    
      @include respond-to("small") {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        > * {
          flex: 1 1 100%;
        }
      }
    }    
  }
  

  /* ==================================================
     SMART EXIT & POST-PURCHASE STRATEGY
     (ExitIntentPopup, PostPurchaseEmails)
  ================================================== */
  .exit-intent-popup {
    // If you need extra styling for the popup container
  }

  /* ==================================================
     Sticky Buy Now
     (Positioned at bottom-right for quick CTA access)
  ================================================== */
  .sticky-buy-now {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;

    button {
      @include button-styles($dominant-purple, white);
      padding: 1rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      @include transition(transform, 0.3s);
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  /* ==================================================
     Mobile Optimization
  ================================================== */
  @include respond-to("small") {
    .above-the-fold {
      flex-direction: column;
      .left-column {
        position: static; // remove sticky on mobile if you prefer
        max-width: 100%;
        padding: 1rem;
      }
      .right-column {
        padding: 1rem;
        margin-top: $spacing-unit;
      }
    }

    .sticky-buy-now {
      bottom: 0.5rem;
      right: 0.5rem;
      button {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
      }
    }
  }
}
