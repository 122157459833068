@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.countdown-timer {
  /* Overall Container */
  max-width: 600px;
  margin: 1rem auto;
  padding: $spacing-unit;
  background: lighten($cream-color, 4%);
  border: 1px solid lighten($dark-purple, 20%);
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba($dark-purple, 0.1);
  color: $dark-purple;
  font-family: $font-secondary;
  text-align: center;
  animation: fadeIn 0.4s ease-in-out;

  /* Loading State */
  .timer-loading {
    font-size: 1rem;
    font-weight: 600;
    color: $dark-purple;
  }

  /* Campaign Name */
  .sale-campaign-name {
    font-family: $font-primary;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.75rem;
    background: rgba($dark-purple, 0.1);
    padding: 0.4rem 1rem;
    border-radius: 6px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  /* Active Countdown */
  .timer-display {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-unit;
    margin: 0.5rem 0;

    /* 🔥 Only Pulse When Under 1 Hour */
    &.urgent {
      animation: pulse 1.5s infinite;
    }

    .timer-icon {
      font-size: 1.2rem;
      color: darken($dark-purple, 5%);
      margin-right: 0.25rem;
    }

    .time-segment {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 50px;
      padding: $spacing-unit-small;
      border-radius: 5px;
      background: rgba($dark-purple, 0.08);
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }

      .time-value {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.2;
        color: ($dark-purple, 10%);
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      }

      .time-label {
        font-size: 0.75rem;
        text-transform: uppercase;
        color: darken($dark-purple, 10%);
        margin-top: 0.2rem;
      }
    }
  }

  /* Sale Ended State */
  .timer-ended {
    background: none;
    border: none;
    padding: 0;

    .empowering-message {
      font-family: $font-paragraph;
      font-size: 1.1rem;
      font-style: italic;
      color: darken($dark-purple, 15%);
      line-height: 1.5;
      margin: auto;
      max-width: 80%;
      text-align: center;
    }
  }

  /* 🎭 Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes pulse {
    0%   { transform: scale(1); }
    50%  { transform: scale(1.03); }
    100% { transform: scale(1); }
  }

  /* 📱 Mobile-First Adjustments */
  @include respond-to("small") {
    max-width: 90%;
    margin: $spacing-unit-small auto;
    padding: $spacing-unit-small;

    .sale-campaign-name {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .timer-display {
      flex-wrap: wrap;
      gap: $spacing-unit-small;

      .time-segment {
        min-width: 45px;

        .time-value {
          font-size: 1.3rem;
        }

        .time-label {
          font-size: 0.65rem;
        }
      }
    }

    .empowering-message {
      font-size: 0.9rem;
    }
  }
}
