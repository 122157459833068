@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.exit-intent-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  text-align: center;
  z-index: 1000;
  width: 90%;
  max-width: 400px;

  .popup-content {
    position: relative;

    h2 {
      font-family: $font-primary;
      color: $dark-purple;
      font-size: 1.4rem;
      margin-bottom: 0.75rem;
    }

    p {
      color: $slate-gray;
      font-size: 1rem;
    }

    .email-input {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid $light-gray;
      border-radius: 5px;
      margin-top: 1rem;
    }

    .claim-btn {
      @include button-styles($rich-gold, white);
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      margin-top: 1rem;
      cursor: pointer;
    }

    .download-btn {
      @include button-styles($rich-gold, white);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      cursor: pointer;
      margin-top: 1rem;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 1.5rem;
      color: $charcoal;
      cursor: pointer;
    }

    .no-thanks {
      font-size: 0.9rem;
      margin-top: 0.5rem;
      cursor: pointer;
      color: $slate-gray;

      &:hover {
        color: $dark-purple;
      }
    }
  }

  /* Mobile Optimization */
  @include respond-to("small") {
    padding: 1.5rem;
    width: 95%;
    max-width: 350px;

    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }

    .claim-btn {
      font-size: 0.9rem;
      padding: 0.6rem 1.2rem;
    }
  }
}
