// src/components/homePage/PromotionSlider.scss

// Import your global branding variables and mixins so every detail is included
@import "../../styles/variables";
@import "../../styles/mixins";

.promotion-slider {
  position: relative;
  overflow: hidden;
  height: 100vh; // Full viewport height for maximum impact

  .custom-swiper {
    height: 100%;
  }
}

.slide {
  position: relative;
  height: 100%;

  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slide-caption {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5); // Semi-transparent overlay for text clarity
    padding: 2rem;
    border-radius: 8px;
    color: #fff;
    @include transition(all, 0.3s);
    @include elevation(2);
    opacity: 0;
    animation: slideIn 1s forwards;
    animation-delay: 0.5s;

    h1 {
      // Use your responsive typography mixin with your brand's marketing font
      @include responsive-font-size(2rem, 2.5rem, 3rem);
      margin-bottom: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      font-family: $font-marketing;
      @include transition(all, 0.3s);
      animation: textFadeIn 1.2s forwards;
    }

    .btn {
      // Leverage your button-styles mixin to ensure consistent interactive elements
      @include button-styles($dominant-purple, #fff);
      padding: 1rem 2rem;
      @include transition(all, 0.3s);
      
      &:hover {
        transform: scale(1.05); // Enhanced micro-interaction for tactile engagement
      }
    }
  }
}

/* Slide-in animation for the caption */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animated text transition for headlines */
@keyframes textFadeIn {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive adjustments using your custom mixin */
@include respond-to("small") {
  .slide-caption {
    left: 5%;
    right: 5%;
    padding: 1rem;

    h1 {
      @include font-size(2rem);
    }

    .btn {
      padding: 0.75rem 1.5rem;
      @include font-size(0.9rem);
    }
  }
}