@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.live-chat {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: $spacing-unit * 2;
  text-align: center;

  h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  #live-chat-widget {
    width: 100%;
    height: 400px;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    background: linear-gradient(
      145deg,
      $dominant-purple,
      lighten($dominant-purple, 10%)
    );
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
