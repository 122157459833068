@import "../../styles/variables";
@import "../../styles/mixins";

.product-card {
  --card-radius: 12px;
  --hover-elevation: 2;
  --card-padding: #{$spacing-unit};
  --image-aspect: 1/1;
  --image-loading-bg: linear-gradient(
    to bottom right,
    $very-light-gray,
    mix($dominant-purple, white, 5%)
  );
  
  position: relative;
  background: white;
  border-radius: var(--card-radius);
  @include elevation(1);
  overflow: hidden;
  @include transition(all 0.3s $ease-out-quad);
  height: 100%; // Ensure equal height in grid

  &:hover {
    @include elevation(var(--hover-elevation));
    transform: translateY(-4px);
    
    .product-card__image {
      transform: scale(1.03);
    }
  }

  &__header {
    position: absolute;
    top: $spacing-unit;
    right: $spacing-unit;
    z-index: 2;
    display: flex;
    gap: $spacing-unit-small;
  }

  &__action {
    @include button-reset;
    @include circle(40px);
    @include flex-center;
    background: rgba(white, 0.9);
    @include elevation(1);
    @include transition(all 0.2s ease);
    
    &:hover {
      background: white;
      @include elevation(2);
      
      .icon {
        color: $dominant-purple;
      }
    }

    .icon {
      color: $charcoal;
      @include transition(color 0.2s ease);
    }

    &--favourited {
      background: rgba($rich-gold, 0.1) !important;
      .icon {
        color: $rich-gold !important;
      }
    }
  
    &--in-cart {
      background: rgba($teal, 0.1) !important;
      .icon {
        color: $teal !important;
      }
    }
  }

  &__image-wrapper {
    position: relative;
    padding-top: 100%; // 1:1 aspect ratio
    overflow: hidden;
    background: var(--image-loading-bg);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include transition(
      transform 0.4s $ease-out-quad,
      opacity 0.3s ease
    );
    opacity: 0;
    font-family: 'object-fit: cover;'; // Polyfill hook

    &[data-loaded="true"] {
      opacity: 1;
    }
  }

  &__content {
    padding: var(--card-padding);
    display: flex;
    flex-direction: column;
    gap: $spacing-unit-small;
    border-top: 2px solid $dominant-purple;
    padding-bottom: $spacing-unit;
  }

  &__title {
    @include text-ellipsis(5);
    font-family: $font-primary;
    color: $dark-purple;
    margin: 0;
    min-height: 3em;
    line-height: 1.4;
    font-size: 1rem;
    
  }

  &__price {
    font-family: $font-marketing; // Use your marketing font
    color: $dominant-purple;
    font-size: 1.25rem;
    font-weight: 600;
    
    &::before {
      font-weight: 400;
    }
  }

  &__reviews {
    display: flex;
    align-items: center;
    gap: $spacing-unit-small;
    margin: 0;
    
    .star-icon {
      color: $rich-gold;
      font-size: 0.9em;
    }
  }

  &__details {
    @include button-styles($dominant-purple, white);
    width: 100%;
    margin-top: auto;
    font-family: $font-action;
    @include flex-center;
    gap: $spacing-unit-small;
  }

  @media (hover: none) {
    &:hover {
      transform: none;
      
      .product-card__image {
        transform: none;
      }
    }
  }

  @include respond-to('medium') {
    --card-padding: #{$spacing-unit * 0.75};
    --hover-elevation: 1;

    &__title {
      font-size: 0.95rem;
      min-height: 2.8em;
    }

    &__price {
      font-size: 1rem;
    }

    &__details {
      padding: 0.75rem;
    }
  }
}