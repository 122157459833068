@import "../../styles/variables";
@import "../../styles/mixins";

.product-upsell {
  padding: 2rem 0;
  background-color: white;
  border-radius: 0.5rem;

  .upsell-header {
    text-align: center;

    h3 {
      font-family: $font-primary;
      color: $dominant-purple;
      font-size: 1.6rem;
      border-bottom: 2px solid $light-gray;
      padding-bottom: 0.75rem;
      margin-bottom: 2rem;
    }
  }

  .upsell-swiper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem 2rem;

    .swiper-slide {
      height: auto;
      display: flex;
      box-sizing: border-box;
    }

    .swiper-pagination-bullet {
      background-color: $dominant-purple;
      opacity: 0.4;
      &-active {
        opacity: 1;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: $dominant-purple;
      font-size: 1.2rem;
    }
  }

  .bundle-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 12px;
    overflow: hidden;
    @include elevation(2);
    @include transition(transform);

    &:hover {
      transform: translateY(-3px);
      @include elevation(3);
    }

    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 8px;
      margin-bottom: 1rem;
      background-color: $very-light-gray;
    }

    .bundle-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h4 {
        font-family: $font-primary;
        font-size: 1.1rem;
        color: $dark-purple;
        line-height: 1.3;
        margin-bottom: 0.25rem;
      }

      p {
        font-family: $font-paragraph;
        font-size: 1rem;
        line-height: 1.4;
        color: $charcoal;
        @include text-truncate(3);
      }

      .pricing {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .old-price {
          text-decoration: line-through;
          color: #999;
          font-size: 0.95rem;
        }

        .new-price {
          color: $rich-gold;
          font-weight: bold;
          font-size: 1.05rem;
        }
      }

      .limited-offer,
      .bonus-offer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.95rem;
        color: $teal;

        svg {
          font-size: 1rem;
        }
      }

      .upgrade-btn {
        @include button-styles($dominant-purple, white);
        font-size: 1rem;
        font-weight: bold;
        margin-top: auto;
      }
    }
  }

  .error {
    color: $teal;
    text-align: center;
    font-weight: 600;
    padding: 1rem;
  }
}
