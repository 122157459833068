@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.support-tickets {
  background-color: white;
  padding: $spacing-unit * 2;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  // Adding gradient background
  background: linear-gradient(135deg, $very-light-gray, white);

  h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    &:after {
      content: "";
      width: 60px;
      height: 2px;
      background: $dominant-purple;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .ticket-input {
    border-radius: 0.25rem;
    border: 1px solid $light-gray;
    &:focus {
      border-color: $dark-purple;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }

  .submit-btn {
    @include button-styles($dominant-purple, white);
    border-radius: 0.5rem;
    padding: $spacing-unit;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease;
    &:hover {
      background-color: darken($dominant-purple, 10%);
      transform: translateY(-2px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }

  .ticket-list {
    margin-top: $spacing-unit * 2;
    display: grid; // Use CSS Grid for more flexible layout
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: $spacing-unit;

    .ticket-card {
      margin-bottom: $spacing-unit;
      border: 1px solid $light-gray;
      border-radius: 0.5rem;
      transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
      &:hover {
        transform: translateY(-5px) rotateX(3deg) rotateY(3deg);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      }
      .ticket-header {
        background-color: $light-gray;
        cursor: pointer;
        padding: $spacing-unit;
        transition:
          background-color 0.3s ease,
          transform 0.3s ease;
        &:hover {
          background-color: darken($light-gray, 5%);
          transform: translateX(10px);
        }
      }
      .ticket-body {
        background-color: white;
        padding: $spacing-unit;
        border-radius: 0 0 0.5rem 0.5rem;
      }
      .btn {
        @include button-styles($dominant-purple, white);
        &:hover {
          background-color: darken($dominant-purple, 10%);
          transform: translateY(-2px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  // Scroll-triggered animations for ticket cards
  .ticket-card {
    opacity: 0;
    transform: translateY(20px);
    transition:
      opacity 0.6s ease,
      transform 0.6s ease;
    &.in-view {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
