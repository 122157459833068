@import "../styles/_variables";
@import "../styles/_mixins";

// Enhanced Product Catalogue with White Focus
.product-catalogue {
  background: white;
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; 
  overflow-x: hidden;

  &__grid {
    display: grid;
    grid-template-columns: [sidebar] minmax(300px, 25%) [main] 1fr;
    gap: $spacing-unit * 2;
    padding: $spacing-unit * 2;

    @include respond-to('large') {
      grid-template-columns: 1fr;
      padding: $spacing-unit;
      margin-top: $spacing-unit * 1;
    }
  }

  &__sidebar {
    position: sticky;
    top: $spacing-unit * 2;
    height: fit-content;
    background: $very-light-gray;
    padding: $spacing-unit * 2;
    border-radius: 8px;
    @include elevation(2);
    
    @include respond-to('large') {
      position: fixed;
      top: 0;
      left: 0;
      width: 300px; // Fixed width for better control
      max-width: 90vw; // Prevent overflow on small devices
      height: 100vh;
      z-index: 1000;
      transform: translateX(-100%);
      transition: transform 0.3s $ease-out-quad;
      will-change: transform;
      overflow-y: auto; // Add scrolling for long content

      &.active {
        transform: translateX(0);
        box-shadow: 4px 0 15px rgba(0,0,0,0.1); // Better visual separation
      }
    }
  }

  &__main {
    min-width: 0;
    padding: $spacing-unit * 2;
    background: white;
    position: relative;
    z-index: 2; // Must be higher than sidebar
    transition: transform 0.3s ease;
    overflow: visible !important; // Critical fix

    @include respond-to('large') {
      padding: $spacing-unit;
      transform: translateX(0);
      width: 100vw;
      
      .sidebar-active & {
        transform: translateX(300px);
        pointer-events: none; // Allow clicking through to closed areas
      }
    }
  }
}

// Pure White Product Grid
.product-list {
  &__empty {
    @include flex-center;
    height: 50vh;
    font-family: $font-paragraph;
    color: $slate-gray;
    font-size: 1.25rem;
    padding: $spacing-unit * 2;
  }
}

.product-grid {
  --min-column-width: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-column-width), 1fr));
  gap: $spacing-unit * 2;
  margin-bottom: $spacing-unit * 3;

  @include respond-to('medium') {
    --min-column-width: 250px;
    gap: $spacing-unit;
  }
}

.product-card__wrapper {
  background: white;
  @include elevation(1);
  border-radius: 8px;
  overflow: hidden;
  @include transition(all $transition-duration ease);
  
  &:hover {
    @include elevation(2);
    transform: translateY(-2px);
  }
}

// Enhanced Pagination
.product-pagination {
  @include flex-center;
  margin-top: $spacing-unit * 3;
  gap: $spacing-unit-small;

  &__item {
    @include button-styles(white, $dominant-purple);
    padding: 0.75rem 1.25rem;
    min-width: 42px;
    border-radius: 4px;
    @include elevation(1);
    
    &--active {
      @include button-styles($dominant-purple, white);
      border: 1px solid $light-purple;
    }
    
    &:hover {
      transform: translateY(-2px);
      @include elevation(2);
    }
  }
}

// Mobile Filter Components
.mobile-filter-toggle {
  @include button-styles($dominant-purple, white);
  position: fixed;
  bottom: $spacing-unit * 2;
  right: $spacing-unit * 2;
  z-index: 1001;
  padding: $spacing-unit $spacing-unit * 2;
  border-radius: 50px;
  @include elevation(3);
  display: none;
  transition: transform 0.2s ease;

  @include respond-to('large') {
    display: block;
  }

  &:hover {
    transform: scale(1.05) translateY(-2px);
  }
}

.mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 999;
  transition: opacity 0.3s;

  @include respond-to('large') {
    display: block;
    opacity: 0;
    pointer-events: none;
    
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}