// _mixins.scss

// ======================
// Layout & Positioning
// ======================
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

// ======================
// Responsive Design
// ======================
@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (max-width: 600px) { @content; }
  } @else if $breakpoint == "medium" {
    @media (max-width: 900px) { @content; }
  } @else if $breakpoint == "large" {
    @media (max-width: 1200px) { @content; }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}";
  }
}

// ======================
// Visual Effects
// ======================
@mixin elevation($level: 1) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

// Backward compatibility alias
@mixin box-shadow($level: 1) {
  @warn "box-shadow mixin is deprecated, use elevation() instead";
  @include elevation($level);
}

// ======================
// Transitions & Animations
// ======================
@mixin transition($properties: all, $duration: $transition-duration, $easing: $ease-out-quad) {
  transition-property: $properties;
  transition-duration: $duration;
  transition-timing-function: $easing;
}

@mixin collapse-transition {
  @include transition(max-height opacity, 0.3s, $ease-out-quad);
}

@mixin rotate-transition {
  @include transition(transform, 0.3s, $ease-out-quad);
}

// ======================
// Typography
// ======================
@mixin text-truncate($lines: 1) {
  @if $lines == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin responsive-font-size($small, $medium, $large) {
  font-size: $small;
  @include respond-to("medium") { font-size: $medium; }
  @include respond-to("large") { font-size: $large; }
}

@mixin font-size($size) {
  font-size: $size;
}

// ======================
// Interactive Elements
// ======================
@mixin button-reset {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-family: inherit;
  color: inherit;
}

@mixin button-styles($bg-color, $text-color) {
  @include button-reset;
  background-color: $bg-color;
  color: $text-color;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  @include transition(all, 0.2s);
  @include elevation(1);

  &:hover {
    filter: brightness(90%);
    @include elevation(2);
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

// ======================
// Special Components
// ======================
@mixin carousel-section-header {
  color: $dominant-purple;
  font-family: $font-primary;
  margin-bottom: $spacing-unit;
  @include responsive-font-size(1.3rem, 1.5rem, 1.6rem);
  border-bottom: 2px solid $light-gray;
  padding-bottom: $spacing-unit-small;
  text-align: center;
}

// ======================
// Utility Mixins
// ======================
@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
}

@mixin text-ellipsis($lines: 1) {
  @include text-truncate($lines);
}