@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.submit-review-form {
  background-color: $cream-color;
  padding: $spacing-unit * 2;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: $spacing-unit * 2;

  label {
    font-family: $font-paragraph;
    color: $dark-purple;
    margin-bottom: $spacing-unit / 2;
    display: block;

    select,
    textarea {
      width: 100%;
      padding: $spacing-unit / 2;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      margin-bottom: $spacing-unit;
      font-family: $font-forms;

      &:focus {
        border-color: $light-purple;
        outline: none;
      }
    }
  }

  button {
    @include button-styles($dominant-purple, white);
    width: 100%;
    margin-top: $spacing-unit;

    &:hover {
      background-color: darken($dominant-purple, 10%);
    }
  }

  .review-message {
    margin-top: $spacing-unit;
    font-family: $font-paragraph;
    color: $dark-purple;
    text-align: center;
  }
}

.submit-review-modal {
  .modal-content {
    background-color: $cream-color;
    padding: $spacing-unit * 2;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border-bottom: 1px solid $light-gray;
    h5 {
      font-family: $font-primary;
      color: $dark-purple;
      margin: 0;
    }
    .close {
      color: $dark-purple;
      opacity: 0.5;
      &:hover {
        color: $dominant-purple;
        opacity: 1;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid $light-gray;
    display: flex;
    justify-content: flex-end;

    .btn {
      @include button-styles($dominant-purple, white);
      &:hover {
        background-color: darken($dominant-purple, 10%);
      }
    }
  }
}
