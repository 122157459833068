// src/components/homePage/ProductHighlights.scss

@import "../../styles/variables";
@import "../../styles/mixins";

.product-highlights {
  // Equal top and bottom spacing
  padding: 4rem 0;
  background-color: $cream-color;
  margin-bottom: 2rem;

  .section-title {
    @include flex-center;
    text-align: center;
    margin-bottom: 2rem;
    font-family: $font-marketing;
    color: $dominant-purple;
    @include responsive-font-size(1.5rem, 2rem, 2.5rem);
  }

  // Ensure each swiper slide centers its content vertically
  .highlights-swiper {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .swiper-button-prev,
    .swiper-button-next {
      color: $dark-purple;
      @include elevation(1);
      &:hover {
        color: $dominant-purple;
      }
    }
    .swiper-pagination-bullet {
      background: $light-gray;
      &.swiper-pagination-bullet-active {
        background: $dominant-purple;
      }
    }
  }

  .product-card {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    @include elevation(2);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // Force a consistent height for each card (adjust as needed)
    height: 100%;

    &:hover {
      transform: translateY(-5px);
      @include elevation(3);
    }

    .image-container {
      width: 100%;
      height: 200px; // Consistent image height
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }
      &:hover img {
        transform: scale(1.1);
      }
    }

    .product-info {
      padding: 1rem;
      text-align: center;

      .product-title {
        font-family: $font-primary;
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
        color: $dark-purple;
      }
      .product-price {
        font-size: 1rem;
        margin-bottom: 1rem;
        color: $rich-gold;
      }
      .product-btn {
        @include button-styles($dominant-purple, #fff);
        text-decoration: none;
        display: inline-block;
        margin-top: 0.5rem;
        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
}

// Mobile-specific adjustments using your respond-to mixin
@include respond-to("small") {
  .product-highlights {
    padding: 2rem 0; // Adjust padding on mobile if needed

    .product-card {
      .image-container {
        height: 150px; // Slightly smaller image on mobile
      }
      .product-info {
        padding: 0.75rem;
        .product-title {
          @include font-size(1rem);
        }
        .product-price {
          @include font-size(0.9rem);
        }
        .product-btn {
          padding: 0.5rem 1rem;
          @include font-size(0.8rem);
        }
      }
    }
  }
}