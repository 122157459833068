@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.payment-form {
  @include container;
  background-color: white;
  padding: $spacing-unit * 2;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  .card-element {
    padding: $spacing-unit-small;
    border: 1px solid $light-gray;
    border-radius: 0.25rem;
    margin-bottom: $spacing-unit;
  }

  .pay-button {
    @include button-styles($dominant-purple, white);
    width: 100%;
    margin-top: $spacing-unit;
  }

  .back-button {
    @include button-styles($light-purple, white);
    width: 100%;
    margin-top: $spacing-unit-small;
  }
}
