@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.live-stats-counter {
  display: flex;
  gap: $spacing-unit;
  flex-wrap: wrap;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: $spacing-unit-small;
  }
}

.stat-item {
  display: flex;
  align-items: center;
  gap: $spacing-unit-small;
  background-color: $very-light-gray;
  padding: $spacing-unit-small;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba($light-purple, 0.1);
  }

  .stat-icon {
    color: $dominant-purple;
    font-size: 1.2rem;
    flex-shrink: 0;
  }

  .stat-text {
    font-family: $font-data;
    font-size: 0.9rem;
    color: $dark-purple;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}

.sale-alert {
  animation: glow-sale 0.8s ease-in-out alternate infinite;
}

@keyframes glow-sale {
  0% {
    text-shadow: 0 0 5px rgba(255, 140, 0, 0.5);
  }
  100% {
    text-shadow: 0 0 15px rgba(255, 140, 0, 0.9);
  }
}
