@import "../../styles/variables";
@import "../../styles/mixins";

.related-products {
  padding: 2rem 0;
  background-color: white;
  border-radius: 0.5rem;

  h2 {
    text-align: center;
    font-family: $font-primary;
    color: $dominant-purple;
    font-size: 1.6rem;
    border-bottom: 2px solid $light-gray;
    padding-bottom: 0.75rem;
    margin-bottom: 2rem;
  }

  .related-swiper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem 2rem;

    .swiper-slide {
      height: auto;
      display: flex;
    }

    .swiper-pagination-bullet {
      background-color: $dominant-purple;
      opacity: 0.4;

      &-active {
        opacity: 1;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: $dominant-purple;
      font-size: 1.2rem;
    }
  }

  .related-product-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 12px;
    overflow: hidden;
    @include elevation(2);
    @include transition(transform);
    cursor: pointer;

    &:hover {
      transform: translateY(-3px);
      @include elevation(3);
    }

    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 8px;
      margin-bottom: 1rem;
      background-color: $very-light-gray;
    }

    h3 {
      font-family: $font-primary;
      font-size: 1.1rem;
      color: $dark-purple;
      line-height: 1.3;
      margin-bottom: 0.25rem;
    }

    .pricing {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 1rem;

      .old-price {
        text-decoration: line-through;
        color: #999;
      }

      .new-price {
        color: $rich-gold;
        font-weight: bold;
      }
    }

    .ratings {
      margin-top: 0.5rem;
      font-size: 0.95rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .filled {
        color: $rich-gold;
      }

      .empty {
        color: $light-gray;
      }

      span {
        color: $charcoal;
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }
    }
  }
}
