@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.testimonials-container {
  display: flex; // equivalent to flex
  overflow-x: auto; // equivalent to overflow-x-auto
  gap: 1rem; // equivalent to space-x-4
  padding: 1rem; // equivalent to p-4

  .testimonial-card {
    border: none; // equivalent to border-none
    border-radius: 0.5rem; // equivalent to rounded-lg
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // equivalent to shadow-md
    text-align: center; // equivalent to text-center
    flex: none; // equivalent to flex-none
    width: 16rem; // equivalent to w-64
    height: auto;

    .card-body {
      display: flex; // equivalent to flex
      flex-direction: column; // equivalent to flex-col
      justify-content: space-between; // equivalent to justify-between
      padding: 1rem; // equivalent to p-4
    }

    .card-title {
      color: $dark-purple; // equivalent to text-dark-purple
      margin-bottom: 0.5rem; // equivalent to mb-2
      font-family: $font-secondary;
    }

    .card-text {
      color: $dark-purple; // equivalent to text-dark-purple
      margin-bottom: 1rem; // equivalent to mb-4
    }

    .card-footer {
      background-color: transparent; // equivalent to bg-transparent
      border-top: 0; // equivalent to border-t-0
      color: $dark-purple; // equivalent to text-dark-purple
      margin-top: auto; // equivalent to mt-auto
      font-family: $font-paragraph;
    }
  }
}
