// src/components/homepage/Homepage.scss

// Import global variables and mixins
@import "../styles/variables";
@import "../styles/mixins";

// Overall Homepage Styles
.homepage {
  background-color: $cream-color;
  font-family: $font-secondary;
  color: $dark-purple;
  overflow-x: hidden;
}

// Custom container based on your mixin
.custom-container {
  @include container;
}

// Each section is distinctly defined for a pristine layout
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s forwards;
  animation-delay: 0.3s;
}

/* Specific Section Overrides */
.hero-section {
  height: 100vh;
  overflow: hidden;
}

.bg-light {
  background-color: $very-light-gray;
}

/* Section Titles */
.section-title {
  text-align: center;
  margin-bottom: 2rem;
  font-family: $font-marketing;
  color: $dominant-purple;
  letter-spacing: 1px;
  @include responsive-font-size(1.8rem, 2.2rem, 2.6rem);
}

/* Primary Button */
.btn.primary-btn {
  @include button-styles($dominant-purple, #fff);
  padding: 1rem 2rem;
  display: inline-block;
  margin-top: 1rem;
  transition: transform 0.3s ease, filter 0.3s ease;
  &:hover {
    transform: translateY(-3px);
    filter: brightness(90%);
  }
}

/* Fade-in Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    animation-delay: 0.2s;
  }
  .section-title {
    @include font-size(1.6rem);
  }
}