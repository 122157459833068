@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.post-purchase-emails {
  background: $very-light-gray;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  font-family: $font-paragraph;
  margin-top: 2rem;
  @include box-shadow(1);

  p {
    font-size: 1rem;
    color: $dark-purple;
    font-weight: 500;
    margin: 0.5rem 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.pending {
    p {
      color: $charcoal;
      font-style: italic;
    }
  }

  &.comingSoon {
    p {
      color: $rich-gold;
      font-weight: bold;
    }
  }

  .cta-link {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background: $rich-gold;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s ease;

    &:hover {
      background: darken($rich-gold, 10%);
    }
  }

  /* Mobile Optimization */
  @include respond-to("small") {
    padding: 1rem;

    p {
      font-size: 0.9rem;
    }

    .cta-link {
      font-size: 0.9rem;
      padding: 0.6rem 1.2rem;
    }
  }
}
