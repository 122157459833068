@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.featured-section {
  margin-bottom: $spacing-unit * 4;

  .carousel {
    @include box-shadow(2);
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: $spacing-unit * 2;

    .carousel-item {
      padding: $spacing-unit * 2;
      background-color: $very-light-gray;
    }

    .carousel-control-prev,
    .carousel-control-next {
      color: $dominant-purple;
    }
  }
}
