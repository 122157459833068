@import "../styles/variables";
@import "../styles/mixins";

.subscriptions-page {
  @include container;
  padding: 2rem 1rem;
  background-color: $very-light-gray;

  .page-title {
    @include responsive-font-size(1.8rem, 2.2rem, 2.5rem);
    font-family: $font-primary;
    color: $dark-purple;
    text-align: center;
    margin-bottom: 3rem;

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background-color: $rich-gold;
      margin: 1rem auto 0;
    }
  }

  .controls-bar {
    @include flex-center;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 1.5rem;

    @include respond-to(medium) {
      flex-direction: column;
    }
  }
}

// 🟣 Interval Toggle Styled
.interval-toggle {
  background: rgba($light-purple, 0.1);
  border-radius: 50px;
  display: inline-flex;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 0.5rem;

  button {
    @include button-reset;
    padding: 0.6rem 1.5rem;
    border-radius: 50px;
    font-family: $font-action;
    font-weight: bold;
    @include transition(background);

    &.active {
      background-color: $dominant-purple;
      color: white;
      @include elevation(1);
    }

    &:hover:not(.active) {
      background-color: rgba($dominant-purple, 0.1);
    }
  }
}

// 🟣 Columns layout for Subscription Groups
.subscription-group-columns {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;

  @include respond-to(medium) {
    flex-direction: column;
  }

  .subscription-category {
    flex: 1 1 30%;
    min-width: 300px;
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    @include elevation(1);
    display: flex;
    flex-direction: column;
  }
}

.subscription-category {
  .category-title {
    font-family: $font-primary;
    color: $dominant-purple;
    border-bottom: 2px solid rgba($light-purple, 0.2);
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    @include responsive-font-size(1.4rem, 1.6rem, 1.8rem);
  }

  .plan-options-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-grow: 1;
  }
}

// 🔥 Uniform Card Layout
.plan-card {
  background-color: $cream-color;
  border-radius: 12px;
  border: 1px solid rgba($light-purple, 0.15);
  padding: 2rem;
  @include elevation(1);
  @include transition(all);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; // important

  &:hover {
    transform: translateY(-5px);
    @include elevation(2);
  }

  &.comparing {
    border-color: $dominant-purple;
    animation: pulseBorder 2s infinite;

    @keyframes pulseBorder {
      0%, 100% { border-color: $dominant-purple; }
      50% { border-color: rgba($dominant-purple, 0.4); }
    }
  }
}

.plan-header {
  position: relative;
  margin-bottom: 1rem;

  .badge {
    @include button-styles($dominant-purple, white);
    position: absolute;
    top: -1rem;
    right: -1rem;
    font-size: 0.75rem;

    &.popular {
      background: linear-gradient(45deg, $dominant-purple, $light-purple);
    }

    &.best-value {
      background: linear-gradient(45deg, $rich-gold, $soft-yellow);
      color: $dark-purple;
    }
  }
}

.plan-interval {
  font-family: $font-action;
  color: $dark-purple;
  margin-bottom: 0.3rem;
}

.plan-price {
  display: flex;
  align-items: baseline;
  gap: 0.3rem;

  .amount {
    font-family: $font-data;
    color: $rich-gold;
    font-weight: 700;
    @include responsive-font-size(1.6rem, 2rem, 2.2rem);
  }

  .interval {
    font-size: 0.9rem;
    color: $slate-gray;
  }
}

.plan-body {
  margin: 1rem 0;
  flex-grow: 1; // ensures the body stretches for alignment

  .plan-description {
    font-family: $font-paragraph;
    color: $charcoal;
    line-height: 1.5;
    min-height: 4em;
  }
}

.plan-features {
  list-style: none;
  margin-top: 1rem;

  li {
    font-family: $font-forms;
    padding: 0.4rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &::before {
      content: "✓";
      color: $rich-gold;
      font-weight: bold;
    }
  }
}

// 🟣 CTA Section Anchored at Bottom
.plan-actions {
  margin-top: auto;
  display: grid;
  gap: 1rem;

  .cta-group {
    display: flex;
    gap: 0.8rem;

    @include respond-to(small) {
      flex-direction: column;
    }
  }
}

.compare-toggle {
    @include button-styles($light-gray, $dark-purple);
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding: 0.55rem 1.1rem;
    border-radius: 6px;
    text-transform: uppercase;
    @include transition(all);
  
    &:hover {
      background-color: rgba($dark-purple, 0.06);
    }
  }
  
  .learn-more {
    @include button-styles($light-purple, white);
    flex: 1;
    font-weight: 600;
    font-size: 0.95rem;
    padding: 0.65rem 1.3rem;
    border-radius: 8px;
    @include transition(all);
  
    &:hover {
      background-color: darken($light-purple, 3%);
    }
  }
  
  .subscribe-now {
    @include button-styles($dominant-purple, white);
    flex: 1;
    font-weight: 700;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    position: relative;
    @include transition(background, transform);
  
    &::after {
      content: "→";
      margin-left: 0.5rem;
      font-weight: 400;
      font-size: 1rem;
      @include transition(transform);
    }
  
    &:hover {
      background-color: darken($dominant-purple, 6%);
      transform: translateY(-1px);
    }
  
    &:hover::after {
      transform: translateX(3px);
    }
  }
  

.compare-cta {
  @include button-styles($rich-gold, $dark-purple);
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &::before {
    content: '⇄';
    font-weight: bold;
  }
}
