// src/styles/components/bundleDetail/BundleIncludedProducts.scss

@import "../../styles/variables";
@import "../../styles/mixins";

.bundle-included-products {
  @include container;
  text-align: center;
  padding: 2rem 0;

  h2 {
    font-family: $font-primary;
    color: $dominant-purple;
    margin-bottom: 1.5rem;
    @include responsive-font-size(1.4rem, 1.6rem, 1.8rem);
    border-bottom: 2px solid $light-gray;
    padding-bottom: 0.5rem;
  }

  .product-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    justify-items: center;
  }

  .included-product-card {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    max-width: 200px;
    text-align: center;
    @include elevation(1);
    @include transition(all, 0.25s, $ease-in-out);

    &:hover {
      @include elevation(2);
      transform: translateY(-3px);
    }

    .included-product-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 0.25rem;
      margin-bottom: 0.75rem;
    }

    .included-product-name {
      font-family: $font-paragraph;
      font-size: 0.95rem;
      color: $dark-purple;
      @include text-truncate(2);

      @include respond-to("small") {
        font-size: 0.9rem;
      }
    }
  }
}
