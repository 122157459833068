@import "../styles/_variables";
@import "../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.checkout {
  &__container {
    @include container;
    background-color: $very-light-gray;
    padding: $spacing-unit * 2;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 1200px;
  }

  &__header {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit;
    text-align: center;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    gap: $spacing-unit * 2;

    @include respond-to("medium") {
      flex-direction: row;
      gap: $spacing-unit;
    }
  }

  &__step {
    flex: 1;
    padding: $spacing-unit;
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: $spacing-unit;

    &--active {
      border-color: $dominant-purple;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
  }
}
