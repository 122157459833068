@import "../../styles/variables";
@import "../../styles/mixins";

// BundleHighlights.scss

.bundle-highlights {
  background-color: $very-light-gray;
  border-radius: 0.65rem;
  padding: 1.25rem 1.5rem;
  margin: 2rem auto;
  max-width: 960px;
  border: 1px solid $light-gray;
  @include elevation(1);

  h2 {
    text-align: center;
    font-family: $font-primary;
    color: $dominant-purple;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    border-bottom: 1px solid $light-gray;
    padding-bottom: 0.5rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 0;

    li {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-family: $font-paragraph;
      font-size: 1rem;
      color: $dark-purple;
      padding: 0.5rem 0;
      border-bottom: 1px dashed $light-gray;

      &:last-child {
        border-bottom: none;
      }

      .icon {
        color: $dominant-purple;
        font-size: 1.1rem;
        flex-shrink: 0;
      }
    }
  }

  @include respond-to("small") {
    padding: 1rem;

    h2 {
      font-size: 1.1rem;
    }

    ul li {
      font-size: 0.95rem;
    }
  }
}
