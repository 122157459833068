@import "../../styles/variables";
@import "../../styles/mixins";

.product-faq {
  background-color: white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  @include elevation(1);
  padding: $spacing-unit;
  margin: $spacing-unit 0;

  .faq-title {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit;
    text-align: center;
    // Base font size for mobile devices
    font-size: 1.5rem;
    @include respond-to("medium") {
      font-size: 1.75rem;
    }
    @include respond-to("large") {
      font-size: 2rem;
    }
  }

  .faq-list {
    .faq-item {
      border-bottom: 1px solid $light-gray;
      margin-bottom: $spacing-unit-small;
      
      &:last-child {
        border-bottom: none;
      }

      .faq-question {
        @include button-reset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-unit;
        background-color: transparent;
        color: $dark-purple;
        font-family: $font-action;
        font-size: 1rem;
        font-weight: bold;
        text-align: left;
        cursor: pointer;
        transition: background-color $transition-duration, color $transition-duration;
        border-radius: 4px;
        
        &:hover {
          background-color: lighten($cream-color, 10%);
          color: $light-purple;
        }
        
        .chevron {
          transition: transform $transition-duration;
        }
      }

      .faq-answer {
        background-color: $very-light-gray;
        border-left: 3px solid $light-purple;
        max-height: 0;
        opacity: 0;
        padding: 0 $spacing-unit;
        overflow: hidden;
        transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
        
        p {
          margin: $spacing-unit-small 0;
          font-family: $font-paragraph;
          color: $dark-purple;
          line-height: 1.4;
        }
      }

      &.active {
        .faq-answer {
          max-height: 500px; // Adjust if necessary to accommodate the content
          opacity: 1;
          padding: $spacing-unit;
        }
        
        .faq-question {
          .chevron {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}