@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.content-card {
  background-color: $white;
  border-radius: 0.25rem;
  overflow: hidden;
  @include box-shadow(2);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    @include box-shadow(3);
  }

  .card-img-top {
    height: 200px;
    object-fit: cover;
  }

  .card-body {
    padding: $spacing-unit * 2;

    .card-title {
      font-family: $font-primary;
      color: $charcoal;
      font-size: 1.5rem;
      margin-bottom: $spacing-unit;
      @include text-truncate;
    }

    .card-summary {
      font-family: $font-paragraph;
      color: $dark-purple;
      font-size: 1rem;
      margin-bottom: $spacing-unit * 1.5;
      height: 3.5em; // Limit summary height to create a consistent card size
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn {
      font-family: $font-action;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      @include transition(background-color, 0.3s, ease-in-out);

      &.btn-primary {
        background-color: $dominant-purple;
        color: white;

        &:hover {
          background-color: darken($dominant-purple, 10%);
        }
      }

      &.btn-secondary {
        background-color: $light-purple;
        color: white;

        &:hover {
          background-color: darken($light-purple, 10%);
        }
      }
    }
  }

  // Responsive adjustments for mobile
  @include respond-to("small") {
    .card-title {
      font-size: 1.25rem;
    }
    .card-summary {
      font-size: 0.9rem;
    }
    .btn {
      padding: 0.4rem 0.8rem;
    }
  }

  @include respond-to("medium") {
    .card-title {
      font-size: 1.4rem;
    }
    .card-summary {
      font-size: 1rem;
    }
  }

  @include respond-to("large") {
    .card-title {
      font-size: 1.5rem;
    }
    .card-summary {
      font-size: 1.1rem;
    }
  }
}
