@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.sticky-buy-now {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }

  &__button {
    @include flex-center;
    @include button-styles($dominant-purple, white);
    gap: $spacing-unit-small;
    padding: 1.25rem 2rem;
    border-radius: 2.5rem;
    font-family: $font-action;
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0.03em;
    box-shadow: 0 4px 12px rgba($charcoal, 0.15);
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;

    // Icon styling
    .icon {
      font-size: 1.3rem;
      transition: transform 0.2s ease;
      flex-shrink: 0;
    }

    // Scarcity message badge
    &::before {
      content: attr(data-scarcity);
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      background: $rich-gold;
      color: white;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      font-size: 0.75rem;
      font-weight: bold;
      transform: scale(0);
      transition: transform 0.2s ease;
    }

    // Hover states
    &:hover {
      transform: translateY(-2px) scale(1.05);
      box-shadow: 0 6px 16px rgba($charcoal, 0.2);

      .icon {
        transform: translateX(3px);
      }

      &::before {
        transform: scale(1);
      }
    }

    // Active state
    &:active {
      transform: translateY(1px) scale(0.98);
    }

    // Focus state
    &:focus-visible {
      outline: 2px solid $rich-gold;
      outline-offset: 3px;
    }

    // Different states
    &[data-status="scarcity"] {
      background: linear-gradient(45deg, $light-purple, $dominant-purple);
      animation: subtle-pulse 2s ease-in-out infinite;
    }

    &[data-status="ended"] {
      background: $slate-gray;
      cursor: not-allowed;
    }
  }

  // Scarcity message text
  &__scarcity-text {
    position: absolute;
    right: 0;
    bottom: calc(100% + 0.5rem);
    background: rgba($cream-color, 0.95);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba($charcoal, 0.1);
    font-size: 0.9rem;
    max-width: 300px;
    transform: translateY(10px);
    opacity: 0;
    transition: all 0.2s ease;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      right: 1rem;
      border-width: 8px;
      border-style: solid;
      border-color: rgba($cream-color, 0.95) transparent transparent transparent;
    }

    .visible & {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Animations
@keyframes subtle-pulse {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-2px) scale(1.03);
  }
}

// Responsive Adjustments
@include media-breakpoint-down(md) {
  .sticky-buy-now {
    bottom: 1rem;
    right: 1rem;

    &__button {
      padding: 1rem 1.75rem;
      font-size: 1rem;

      .icon {
        font-size: 1.1rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .sticky-buy-now {
    bottom: 0.75rem;
    right: 0.75rem;

    &__button {
      padding: 0.75rem 1.5rem;
      border-radius: 2rem;
      font-size: 0.95rem;

      &::before {
        font-size: 0.65rem;
        padding: 0.2rem 0.6rem;
      }
    }

    &__scarcity-text {
      max-width: 250px;
      font-size: 0.85rem;
      right: -1rem;
    }
  }
}
