@import "../styles/_variables";
@import "../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.cart-page {
  @include container;
  background-color: white;
  padding: $spacing-unit * 3;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    text-align: center;
  }

  p {
    font-family: $font-paragraph;
    color: $dark-purple;
    text-align: center;
    margin-bottom: $spacing-unit * 2;
  }

  .cart-items {
    display: flex;
    flex-direction: column;
    gap: $spacing-unit * 2;

    .cart-item {
      display: flex;
      align-items: center;
      padding: $spacing-unit * 1.5;
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        margin-right: $spacing-unit * 2;
      }

      .cart-item-info {
        display: flex;
        flex-direction: column;
        flex: 1;

        h2 {
          font-family: $font-secondary;
          color: $dark-purple;
          margin-bottom: $spacing-unit-small;
          font-size: 1.2rem;
        }

        p {
          color: $dark-purple;
          margin-bottom: $spacing-unit-small;
        }

        button {
          @include button-styles($dominant-purple, white);
          align-self: flex-start;
          margin-top: $spacing-unit-small;
          padding: 0.5rem 1.5rem; /* Adjusted padding */
          &:hover {
            background-color: darken($dominant-purple, 10%);
          }
        }
      }
    }
  }

  .checkout-button {
    @include button-styles($dominant-purple, white);
    width: 100%;
    margin-top: $spacing-unit * 2;
    padding: 0.75rem; /* Adjusted padding */

    &:hover {
      background-color: darken($dominant-purple, 10%);
    }
  }
}
