@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.price-breakdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: $very-light-gray;
  padding: $spacing-unit;
  border-radius: 0.75rem;
  border: 1px solid rgba($light-purple, 0.1);
  box-shadow: 0 4px 24px rgba($charcoal, 0.06);
  transition: transform 0.3s $font-action;
  width: 100%; // Ensures proper containment
  max-width: 800px; // Prevent excessive widening on large screens
  margin: 0 auto; // Center component

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 28px rgba($charcoal, 0.09);
  }

  .original-price {
    font-family: $font-data;
    font-size: clamp(0.875rem, 2vw, 1rem); // Responsive font size
    color: $slate-gray;
    text-decoration: line-through;
    margin-bottom: $spacing-unit-small;
    opacity: 0.9;
  }

  .current-price {
    font-family: $font-marketing;
    font-size: clamp(1.5rem, 5vw, 2rem); // Fluid typography
    font-weight: 600;
    color: $dark-purple;
    margin-bottom: $spacing-unit-small;
    letter-spacing: -0.03em;
    text-shadow: 0 2px 4px rgba($dominant-purple, 0.08);
    line-height: 1.2; // Better for wrapping
  }

  .savings-highlight {
    font-family: $font-action;
    font-size: clamp(0.85rem, 2.5vw, 0.95rem);
    font-weight: 600;
    color: darken($rich-gold, 8%);
    background: linear-gradient(
      to right,
      rgba($cream-color, 0.9),
      rgba($soft-yellow, 0.7)
    );
    padding: 0.6rem 1.2rem;
    border-radius: 2rem;
    margin-bottom: $spacing-unit;
    border: 1px solid rgba($rich-gold, 0.15);
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    backdrop-filter: blur(2px);
    white-space: nowrap; // Prevent text wrapping
  }

  .trust-badges {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(200px, 100%), 1fr)
    ); // Improved responsive grid
    gap: $spacing-unit-small;
    width: 100%;
    padding-top: $spacing-unit-small;
    border-top: 1px solid rgba($light-purple, 0.08);

    .badge {
      font-family: $font-navigation;
      background: rgba($cream-color, 0.6);
      color: $dark-purple;
      font-size: clamp(0.8rem, 2vw, 0.9rem); // Fluid typography
      padding: 0.8rem;
      border-radius: 0.5rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      transition: all 0.25s $font-action;
      backdrop-filter: blur(4px);
      border: 1px solid rgba($dominant-purple, 0.05);
      min-width: min-content; // Prevent overflow
      text-align: center; // Ensure text alignment

      &:hover {
        background: rgba($soft-yellow, 0.7);
        transform: translateY(-2px);
      }

      svg {
        font-size: clamp(1.1rem, 3vw, 1.3rem); // Responsive icons
        color: $light-purple;
        flex-shrink: 0; // Prevent icon squishing
      }
    }
  }

  // Mobile-first media queries
  @include respond-to("small") {
    padding: $spacing-unit-small;
    border-radius: 0.6rem;

    .current-price {
      margin-bottom: 0.25rem;
    }

    .savings-highlight {
      margin-bottom: $spacing-unit-small;
      padding: 0.5rem 1rem;
    }

    .trust-badges {
      grid-template-columns: 1fr;
      gap: 0.5rem;

      .badge {
        padding: 0.7rem;
        justify-content: flex-start; // Better mobile alignment
        text-align: left;
      }
    }
  }

  // Tablet landscape+ sizes
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: $spacing-unit;

    .trust-badges {
      grid-template-columns: repeat(2, 1fr); // 2 columns for tablet
    }
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;

    .trust-badges .badge,
    &:hover {
      transform: none;
    }
  }
}
