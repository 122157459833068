@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.customer-reviews {
  background: $very-light-gray;
  padding: $spacing-unit;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: $font-paragraph;
  max-width: 800px;
  margin: auto;

  h3 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit;
    text-align: center;
  }

  // ⭐ Rating Breakdown Section
  .rating-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $cream-color;
    padding: $spacing-unit-small;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: $spacing-unit;

    .average-rating {
      font-size: 2rem;
      font-weight: bold;
      color: $dominant-purple;

      .big-number {
        font-size: 3rem;
        color: $rich-gold;
      }

      p {
        font-size: 0.9rem;
        color: $charcoal;
      }
    }

    .rating-bars {
      width: 60%;

      .rating-row {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        span {
          font-size: 0.9rem;
          width: 40px;
        }

        .bar {
          flex: 1;
          height: 6px;
          background: $light-gray;
          border-radius: 5px;
          overflow: hidden;
          position: relative;

          .fill {
            height: 100%;
            background: $rich-gold;
            transition: width 0.5s ease-in-out;
          }
        }
      }
    }
  }

  // 🔀 Sorting Dropdown
  .sort-dropdown {
    width: 100%;
    padding: 8px;
    font-family: $font-secondary;
    border: 1px solid $light-gray;
    border-radius: 4px;
    background: white;
    margin-bottom: $spacing-unit;
    cursor: pointer;
  }

  // ✍️ Individual Review Styles
  .review-item {
    background: white;
    padding: $spacing-unit;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    margin-bottom: $spacing-unit;
    font-family: $font-paragraph;
    position: relative;

    strong {
      font-family: $font-special;
      color: $dark-purple;
      font-size: 1rem;
    }

    .review-rating {
      margin: 5px 0;

      .star {
        color: $light-gray;
        font-size: 1.1rem;
      }

      .filled {
        color: $rich-gold;
      }
    }

    p {
      font-size: 0.95rem;
      color: $charcoal;
    }
  }

  // 🔄 Load More Button
  .load-more {
    @include button-styles($dominant-purple, white);
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.8rem;
    font-size: 1rem;
    font-family: $font-action;
    margin-top: $spacing-unit;
  }

  // ✨ Review Submission Redirect
  .review-dashboard-link {
    text-align: center;
    margin-top: 1.5rem;

    p {
      font-size: 0.95rem;
      color: $charcoal;
    }

    button {
      @include button-styles($teal, white);
      padding: 0.8rem;
      font-size: 1rem;
      font-family: $font-action;
      width: 100%;
    }
  }
}

// 🌍 Mobile Responsiveness
@include respond-to("small") {
  .customer-reviews {
    padding: $spacing-unit-small;
  }

  .rating-summary {
    flex-direction: column;
    align-items: center;

    .rating-bars {
      width: 100%;
    }
  }
}
