@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.filter-sidebar {
  --sidebar-padding: #{$spacing-unit * 1};
  background: white;
  padding: var(--sidebar-padding);
  border-radius: 8px;
  @include elevation(2);
  // width: 250px;
  min-height: 600px;
  transition: transform 0.3s $ease-out-quad;
  overflow-x: hidden;
  backface-visibility: hidden;

  // @include respond-to('large') {
  //   // position: fixed;
  //   // top: 0; // Changed from $spacing-unit * 4
  //   // left: 0; // Added left positioning
  //   // transform: translateX(-100%);
  //   // width: 300px; // Fixed width instead of 85vw
  //   // max-width: 90vw; // Prevent overflow
  //   // height: 100vh; // Full viewport height
  //   // z-index: 1;
  //   // overflow-y: auto;
  //   // border-radius: 0;
  //   // box-shadow: 4px 0 15px rgba(0,0,0,0.1); // Added shadow

  //   // &.active {
  //   //   transform: translateX(0);
  //   //   box-shadow: 4px 0 15px rgba(0,0,0,0.2); // Stronger shadow
  //   //   pointer-events: all; // Enable interactions

  //   //   // Hardware acceleration
  //   //   will-change: transform;
  //   //   backface-visibility: hidden;
  //   // }

  //   // + .product-catalogue__main {
  //   //   transform: translateX(300px);
  //   // }

  //   // // Removed &__groups mobile specific styles
  // }

  &__toggle {
    @include button-reset;
    @include flex-center;
    gap: $spacing-unit-small;
    width: 100%;
    padding: $spacing-unit;
    background: $dominant-purple;
    color: white;
    border-radius: 6px;
    @include elevation(1);
    @include transition(all 0.2s ease);
    position: sticky;
    top: $spacing-unit;
    z-index: 1001;

    @include respond-to('large') {
      top: 0; // Adjusted for mobile header
    }

    &:hover {
      transform: translateY(-1px);
      @include elevation(2);
    }

    &-text {
      font-family: $font-action;
      font-weight: 500;
      font-size: 0.95rem;
    }

    &-icon {
      width: 20px;
      height: 20px;
      fill: currentColor;
      margin-left: auto;
      transform: rotate(0deg);
      @include transition(transform 0.3s ease);

      .active & {
        transform: rotate(180deg);
      }
    }
  }

  &__heading {
    @include font-size(1.5rem);
    color: $dark-purple;
    margin: $spacing-unit * 2 0;
    padding-bottom: $spacing-unit;
    border-bottom: 2px solid $very-light-gray;
  }
}

.filter-group {
  display: grid;
  gap: $spacing-unit;
  align-items: start;
  min-width: 100%; // Changed from 70%
  flex-shrink: 0;

  @include respond-to('large') {
    padding: 0 $spacing-unit; // Added side padding
  }
}

.filter-label {
  @include font-size(0.875rem);
  color: $charcoal;
  font-family: $font-secondary;
  font-weight: 600;
}

.filter-input,
.filter-select {
  @include transition(border-color 0.2s ease);
  background: white;
  border: 2px solid $light-gray;
  border-radius: 6px;
  padding: $spacing-unit-small $spacing-unit;
  font-family: $font-forms;
  box-sizing: border-box;
  width: calc(100% - 2px);

  &:focus {
    border-color: $dominant-purple;
    outline: none;
    @include elevation(1);
  }
}

.filter-select-wrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: $spacing-unit;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: $dominant-purple transparent transparent transparent;
    pointer-events: none;
  }
}

.filter-select {
  appearance: none;
  padding-right: $spacing-unit * 2;
  cursor: pointer;
}

.filter-slider {
  padding: $spacing-unit 0;
  position: relative;

  .rc-slider {
    &-rail {
      background: $very-light-gray;
      height: 4px;
      width: calc(100% - 20px) !important;
      margin: 0 auto;
    }

    &-track {
      background: $dominant-purple;
      height: 4px;
    }

    &-handle {
      width: 20px;
      height: 20px;
      margin-top: -8px;
      border: 2px solid $dominant-purple;
      background: white;
      @include elevation(1);
      @include transition(all 0.2s ease);
      transform: translateX(-50%);

      &:hover {
        border-color: $dark-purple;
      }
    }
  }

  &-values {
    @include flex-center;
    margin-top: $spacing-unit;
    font-family: $font-data;
    color: $slate-gray;
    font-weight: 500;
  }
}