@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.order-history {
  @include container;
  margin-top: $spacing-unit * 3;

  h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    text-align: center;
  }

  .order-list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-unit * 2;
  }

  .order-item {
    flex: 1 1 calc(50% - $spacing-unit * 2);
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 0.25rem;
    @include box-shadow(1);

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $font-secondary;
      color: $dark-purple;
    }

    .card-body {
      p {
        margin-bottom: $spacing-unit;
        font-family: $font-paragraph;
        color: $dark-purple;
      }

      .list-group-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $font-paragraph;
        color: $dark-purple;
      }

      .btn {
        @include button-styles($dominant-purple, white);
        margin-top: $spacing-unit;
        padding: 0.375rem 0.75rem;

        &:hover {
          background-color: darken($dominant-purple, 10%);
        }
      }
    }
  }
}
