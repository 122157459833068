@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.newsletter-signup {
  text-align: center;

  h3 {
    color: $dark-purple; // equivalent to text-dark-purple
    margin-bottom: 2rem; // equivalent to mb-8
    font-family: $font-primary;
  }

  .form-group {
    display: flex; // equivalent to flex
    justify-content: center; // equivalent to justify-center

    .form-control {
      max-width: 24rem; // equivalent to max-w-md
      margin-right: 1rem; // equivalent to mr-4
      transition: border-color 0.3s; // equivalent to transition-colors
      &:focus {
        border-color: $dark-purple; // equivalent to focus:border-dark-purple
      }
    }
  }

  .btn {
    background-color: $dominant-purple; // equivalent to bg-dominant-purple
    color: white; // equivalent to text-white
    padding: 0.5rem 1rem; // equivalent to py-2 px-4
    border-radius: 0.25rem; // equivalent to rounded
    &:hover {
      background-color: $dark-purple; // equivalent to bg-dark-purple
    }
  }

  p {
    margin-top: 1rem; // equivalent to mt-4
    color: $dark-purple; // equivalent to text-dark-purple
    font-family: $font-paragraph;
  }
}
