@import "../styles/_variables";
@import "../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.user-dashboard {
  .dashboard-header {
    background-color: $very-light-gray;
    text-align: center;
    padding: $spacing-unit * 2;
    margin-bottom: $spacing-unit * 2;
    border-radius: 0.5rem;
    h1 {
      font-family: $font-primary;
      color: $dark-purple;
    }
  }

  .sidebar {
    background-color: $light-gray;
    padding: $spacing-unit * 2;
    border-radius: 0.5rem;
    .nav-link {
      color: $dark-purple;
      font-family: $font-secondary;
      margin-bottom: $spacing-unit;
      &:hover {
        color: $dominant-purple;
      }
    }
  }

  .content {
    .section {
      background-color: white;
      padding: $spacing-unit * 2;
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: $spacing-unit * 3;

      h2 {
        font-family: $font-primary;
        color: $dark-purple;
        margin-bottom: $spacing-unit;
      }

      .product-card {
        border: 1px solid $light-gray;
        border-radius: 0.5rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s;

        &:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        }

        .card-body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h5 {
            font-family: $font-secondary;
            color: $dark-purple;
            margin-bottom: $spacing-unit-small;
          }
        }

        &.reviewed {
          background-color: $very-light-gray;
          h5 {
            color: $dominant-purple;
          }
        }
      }
    }
  }
}
