@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.search-component {
  padding: $spacing-unit * 2;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    &:after {
      content: "";
      width: 60px;
      height: 2px;
      background: $dominant-purple;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .form-control {
    border: 1px solid $light-gray;
    border-radius: 0.25rem;
    transition:
      border-color 0.3s ease,
      box-shadow 0.3s ease;
    &:focus {
      border-color: $dark-purple;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }

  .btn {
    @include button-styles($dominant-purple, white);
    width: 100%;
    border-radius: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease;
    &:hover {
      background-color: darken($dominant-purple, 10%);
      transform: translateY(-2px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }

  .search-results {
    margin-top: $spacing-unit * 2;
    .search-result-item {
      border-bottom: 1px solid $light-gray;
      padding-bottom: $spacing-unit;
      margin-bottom: $spacing-unit;
      transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      }
      h3 {
        font-family: $font-secondary;
        color: $dark-purple;
        margin-bottom: $spacing-unit-small;
      }
      p {
        font-family: $font-paragraph;
        color: $dark-purple;
      }
    }
  }
}
