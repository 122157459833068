@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.customer-photos {
  background-color: white;
  padding: 2rem 0;
  @include container;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-bottom: $spacing-unit;
  }
  
  .spinner {
    width: 36px;
    height: 36px;
    border: 4px solid $very-light-gray;
    border-top: 4px solid $rich-gold;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }  

  h3 {
    text-align: center;
    font-family: $font-primary;
    color: $dominant-purple;
    margin-bottom: $spacing-unit;
    @include responsive-font-size(1.4rem, 1.6rem, 1.8rem);
    
    &:focus-visible {
      outline: 2px dashed $dominant-purple;
      outline-offset: 4px;
    }
  }

  .loading-text,
  .error-text,
  .no-photos {
    text-align: center;
    font-family: $font-paragraph;
    font-size: clamp(0.9rem, 3vw, 0.95rem);
    color: $slate-gray;
    margin-bottom: $spacing-unit;
    
    &:after {
      content: '...';
      animation: loadingDots 1.5s infinite;
      display: inline-block;
      width: 1em;
      color: $rich-gold;
    }
  }

  .photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(140px, 100%), 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
    
    @include respond-to("medium") {
      gap: 1.5rem;
    }
  }

  .photo-card {
    border-radius: 6px;
    overflow: hidden;
    @include elevation(1);
    aspect-ratio: 1/1;
    position: relative;
    background: $very-light-gray;
    
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: 6px;
      transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      will-change: transform;
      backface-visibility: hidden;
    }

    &:hover img,
    &:focus-within img {
      transform: scale(1.03);
    }
  }

  .upload-bar {
    background-color: rgba($very-light-gray, 0.9);
    border: 1px solid $soft-yellow;
    border-radius: 6px;
    padding: 1.25rem;
    color: $dark-purple;
    @include flex-center;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: clamp(0.8rem, 2.5vw, 0.85rem);
    text-align: center;
    backdrop-filter: blur(2px);

    .upload-button {
      @include button-styles($rich-gold, white);
      font-family: $font-action;
      font-size: clamp(0.85rem, 3vw, 0.9rem);
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.8rem 1.5rem;
      min-height: 44px;
      transition: transform 0.1s ease;

      &:active {
        transform: scale(0.98);
      }

      &:focus {
        @include elevation(2);
        outline: 2px solid $rich-gold;
        outline-offset: 2px;
      }
    }

    span {
      font-family: $font-paragraph;
      color: $dark-purple;

      svg {
        vertical-align: middle;
        margin: 0 0.25rem;
        width: 1.2em;
        height: 1.2em;
      }

      strong {
        color: $rich-gold;
        margin: 0 0.2rem;
        font-weight: 600;
      }
    }
  }

  

  @include respond-to("small") {
    .upload-bar {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      
      .upload-button {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }
}

@keyframes loadingDots {
  0%, 20% { content: '.'; }
  40% { content: '..'; }
  60%, 100% { content: '...'; }
}