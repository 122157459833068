@import "../../styles/_variables";
@import "../../styles/_mixins";
@import "~bootstrap/scss/bootstrap";

.faq-list {
  h2 {
    font-family: $font-primary;
    color: $dark-purple;
    margin-bottom: $spacing-unit * 2;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    &:after {
      content: "";
      width: 60px;
      height: 2px;
      background: $dominant-purple;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h3 {
    font-family: $font-secondary;
    color: $dominant-purple;
    margin-bottom: $spacing-unit * 1.5;
    text-transform: uppercase;
    font-weight: bold;
    transition: color 0.3s ease;
    &:hover {
      color: darken($dominant-purple, 10%);
    }
  }

  .card {
    border: 1px solid $light-gray;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: white;
    padding: $spacing-unit * 1.5;

    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px) scale(1.02);
    }

    &.active {
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
      border-color: $dominant-purple;

      .card-header {
        background-color: $dominant-purple;
        color: white;
        transition:
          background-color 0.3s ease,
          color 0.3s ease;
      }
    }

    .card-header {
      background-color: $light-gray;
      cursor: pointer;
      padding: $spacing-unit * 1.5;
      font-size: 1.2rem;
      font-weight: bold;
      transition:
        background-color 0.3s ease,
        color 0.3s ease;

      &:hover {
        background-color: darken($light-gray, 5%);
      }
    }

    .card-body {
      padding: $spacing-unit * 1.5;
      background-color: white;
      font-size: 1rem;
      line-height: 1.6;
      color: $dark-purple;
    }
  }
}
