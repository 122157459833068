@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

// ExpressCheckout.scss

.express-checkout {
  --ec-bg: #{rgba($cream-color, 0.95)};
  --ec-border: #{rgba($light-purple, 0.15)};
  --ec-gap: clamp(#{$spacing-unit-small}, 2vw, #{$spacing-unit});
  --ec-icon-size: clamp(1.25rem, 3.5vw, 1.5rem);

  display: grid;
  gap: var(--ec-gap);
  padding: var(--ec-gap);
  background: var(--ec-bg);
  border-radius: clamp(0.75rem, 2vw, 1rem);
  border: 1px solid var(--ec-border);
  backdrop-filter: blur(8px);
  container-type: inline-size;

  // Best Seller Badge
  .best-seller-badge {
    --badge-bg: #{linear-gradient(45deg, $rich-gold 0%, $soft-yellow 100%)};

    @include flex-center;
    gap: 0.5em;
    padding: 0.6em 1.2em;
    background: var(--badge-bg);
    border-radius: 2em;
    font-family: $font-marketing;
    font-size: clamp(0.875rem, 2.5cqi, 1rem);
    color: $dark-purple;
    width: fit-content;
    margin: 0 auto;
    backdrop-filter: blur(4px);

    .best-seller-icon {
      color: currentColor;
      font-size: 1.2em;
    }
  }

  // Save Payment Checkbox
  .save-payment-checkbox {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0.75em;
    font-family: $font-navigation;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 0.5em;
    transition: background-color 0.3s $font-action;

    &:hover {
      background: rgba($light-purple, 0.05);
    }

    input[type="checkbox"] {
      --size: 1.25em;

      appearance: none;
      width: var(--size);
      height: var(--size);
      border: 2px solid $light-purple;
      border-radius: 0.35em;
      display: grid;
      place-content: center;

      &:checked {
        background: $light-purple;

        &::before {
          content: "✓";
          color: $cream-color;
          font-weight: 900;
        }
      }
    }
  }

  // Express Checkout Button
  .express-checkout-button {
    --btn-bg: #{linear-gradient(
        135deg,
        $light-purple 0%,
        $dominant-purple 100%
      )};
    --btn-hover-bg: #{linear-gradient(
        135deg,
        darken($light-purple, 8%) 0%,
        darken($dominant-purple, 8%) 100%
      )};

    display: inline-flex;
    gap: 0.75em;
    align-items: center;
    justify-content: center;
    padding: clamp(0.75rem, 3vw, 1rem);
    background: var(--btn-bg);
    color: white;
    border: none;
    border-radius: 0.5em;
    font-family: $font-action;
    font-size: clamp(0.9rem, 3cqi, 1.1rem);
    font-weight: 600;
    cursor: pointer;
    transition:
      background 0.3s $font-action,
      transform 0.2s ease;
    width: 100%;

    &:hover {
      background: var(--btn-hover-bg);
      transform: translateY(-1px);
    }

    .checkout-icon {
      font-size: var(--ec-icon-size);
      color: inherit;
    }
  }

  // Container Queries
  @container (width < 400px) {
    .save-payment-checkbox {
      font-size: 0.9em;
    }

    .express-checkout-button {
      flex-direction: column;
      gap: 0.25em;
    }
  }
}
