// Color Palette
$light-purple: #745275;
$dark-purple: #5d445f;
$cream-color: #e3d6b2;
$dominant-purple: #735b73;
$soft-yellow: #f4e3af;
$slate-gray: #708090;
$rich-gold: #a88905;
$teal: #006d77;
$deep-blue: #003b73;
$charcoal: #36454f;
$olive-green: #808000;
$copper: #b87333;
$light-gray: #d3d3d3;
$very-light-gray: #f8f8f8;

// Fonts
$font-primary: "Georgia", serif;
$font-secondary: "Arial", sans-serif;
$font-action: "Gotham", sans-serif;
$font-paragraph: "Helvetica Neue", sans-serif;
$font-marketing: "Neutra Display", sans-serif;
$font-decorative: "Goudy Trajan", serif;
$font-navigation: "Avenir", sans-serif;
$font-data: "Helios", sans-serif;
$font-forms: "Lucida Grande", sans-serif;
$font-special: "Zona", sans-serif;

// Spacing
$spacing-unit: 1rem;
$spacing-unit-small: 0.5rem;

// Transitions
$transition-duration: 250ms;
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);

// Breakpoints
$breakpoint-small: 600px;
$breakpoint-medium: 900px;
$breakpoint-large: 1200px;