@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.footer-navigation {
  margin-top: $spacing-unit * 4;
  padding: $spacing-unit * 2;
  background-color: $charcoal;
  color: white;
  border-radius: 0.25rem;
  text-align: center;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin: $spacing-unit / 2;

      a {
        color: $light-gray;
        font-size: 0.9rem;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: $olive-green;
        }
      }
    }
  }
}
