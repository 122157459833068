@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.product-ticker {
  width: 100%;
  overflow: hidden;
  background: #fff; // Keeping your clean white aesthetic
  border-top: 0.8px solid $light-purple; // Softer than #ddd, matches brand palette
  border-bottom: 0.8px solid $light-purple; // Ditto
  padding: $spacing-unit-small 0;
  white-space: nowrap;
  position: relative;
}

.ticker-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  position: relative;
  mask-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95) 15%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.95) 85%, rgba(255, 255, 255, 0));
}

.ticker-content {
  display: flex;
  gap: 50px; /* Increased spacing for better readability */
  animation: ticker-scroll 20s linear infinite; /* Slowed down for a more natural pace */
  min-width: max-content; /* Ensures continuous, seamless flow */
}

.ticker-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Slightly more space between icon & text */
  font-size: 1rem; /* Increased text size slightly */
  font-weight: 500;
  font-family: $font-paragraph; /* Using your brand font for a premium feel */
  color: $dark-purple; /* Less harsh than #333, fits brand colors */
  padding: 6px 14px; /* More padding for better breathing space */
  white-space: nowrap;
}

.ticker-icon {
  font-size: 1.1rem; /* Slightly larger for balance */
  color: $olive-green; /* Softer than #555, blends naturally */
}

@keyframes ticker-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* Smooth Pause Effect */
.ticker-content:hover {
  animation-play-state: paused;
  transition: animation-play-state 0.3s ease-in-out;
}
