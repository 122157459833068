@import "../../styles/variables";
@import "../../styles/mixins";

.product-licensing {
  @include container;
  background-color: $very-light-gray;
  padding: 2rem;
  border-radius: 8px;
  margin: 2rem auto;
  width: 100%;

  .licensing-title {
    @include carousel-section-header;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .licensing-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .license-card {
    background-color: white;
    border-left: 6px solid transparent;
    padding: 1.5rem;
    border-radius: 6px;
    @include elevation(1);
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    @include transition;

    &.allowed {
      border-color: $olive-green;
    }

    &.conditional {
      border-color: $rich-gold;
    }

    &.not-allowed {
      border-color: $light-purple;
      background-color: #fef6f8;
    }

    .license-icon-container {
      @include flex-center;
      @include circle(2.5rem);
      background-color: $very-light-gray;
      flex-shrink: 0;

      .license-icon {
        font-size: 1.5rem;
        color: $dominant-purple;
      }
    }

    .license-content {
      .license-type {
        font-family: $font-primary;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: $dark-purple;
      }

      .license-description {
        font-family: $font-paragraph;
        font-size: 0.95rem;
        color: $charcoal;
        line-height: 1.5;
      }

      .license-notice {
        display: flex;
        align-items: center;
        font-size: 0.85rem;
        color: $rich-gold;
        gap: 0.5rem;
        margin-top: 0.5rem;

        .notice-icon {
          color: $rich-gold;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding: 3rem 2rem;
    
    .licensing-grid {
      gap: 2rem;
    }

    .license-card {
      padding: 2rem;
      
      .license-content {
        .license-type {
          font-size: 1.3rem;
        }
        
        .license-description {
          font-size: 1rem;
        }
      }
    }
  }
}