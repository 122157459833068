@import "../../styles/_variables";
@import "../../styles/_mixins";

.user-profile {
  flex: 1;
  min-width: 300px;
  padding: $spacing-unit * 2;
  background-color: #f9f9f9;
  border: 1px solid $light-gray;
  border-radius: 0.25rem;
  @include box-shadow(1);

  h2 {
    font-size: 1.5em;
    margin-bottom: $spacing-unit;
    color: $dark-purple;
    font-family: $font-primary;
  }

  p {
    margin-bottom: $spacing-unit;
    font-size: 1em;
    color: $dark-purple;
    font-family: $font-paragraph;

    &:first-of-type {
      font-weight: bold;
    }
  }
}
