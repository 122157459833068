@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.product-image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  margin: auto;
  text-align: center;

  // Main image styling—ensure the main image is wrapped in an element with .main-image
  .main-image {
    cursor: pointer;
    img {
      max-width: 100%;
      height: auto;
      display: block;
      border-radius: 0.5rem;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // Thumbnail images styling
  .thumbnail-images {
    display: flex;
    flex-wrap: nowrap; // Prevents items from wrapping to a new line
    overflow-x: auto; // Allows horizontal scrolling if items overflow
    gap: $spacing-unit-small;
    margin-top: $spacing-unit;
    padding: $spacing-unit-small;
    // You can keep justify-content: center or switch to flex-start if you want them left-aligned
    justify-content: flex-start;
    max-width: 100%;
    // Optional: If you find items are not horizontally aligned, add:
    white-space: nowrap;

    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      cursor: pointer;
      border: 2px solid $light-gray;
      border-radius: 0.25rem;
      transition: border-color 0.2s ease-in-out;
      &.active,
      &:hover {
        border-color: $dominant-purple;
      }
    }
  }

  // Lightbox styles remain unchanged
  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include respond-to("small") {
    max-width: 100%;
    .thumbnail-images {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
