@import "../../styles/variables";
@import "../../styles/mixins";

.pagination {
  margin-top: $spacing-unit * 3;
  padding: $spacing-unit 0;

  &__container {
    @include flex-center;
    gap: $spacing-unit;
  }

  &__button {
    @include button-reset;
    @include elevation(1);
    padding: $spacing-unit $spacing-unit * 1.5;
    border-radius: 8px;
    background: $dominant-purple;
    color: white;
    font-family: $font-action;
    font-weight: 500;
    @include transition(all 0.2s $ease-out-quad);
    
    &:hover:not(:disabled) {
      @include elevation(2);
      transform: translateY(-1px);
      background: darken($dominant-purple, 5%);
    }

    &:active:not(:disabled) {
      transform: translateY(0);
    }

    &:disabled {
      background: mix($dominant-purple, white, 30%);
      cursor: not-allowed;
      opacity: 0.7;
      @include elevation(0);
    }

    &-text {
      position: relative;
      display: block;
      
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include transition(all 0.2s ease);
      }
    }

    &--prev &-text::after {
      content: "←";
      margin-left: $spacing-unit-small;
    }

    &--next &-text::after {
      content: "→";
      margin-right: $spacing-unit-small;
    }
  }

  &__counter {
    font-family: $font-data;
    color: $slate-gray;
    padding: 0 $spacing-unit;
    
    strong {
      color: $dark-purple;
      font-weight: 600;
    }
  }

  @include respond-to('medium') {
    &__container {
      flex-direction: column;
      width: 100%;
    }

    &__button {
      width: 100%;
      order: 2;
    }

    &__counter {
      order: 1;
      margin-bottom: $spacing-unit;
    }
  }
}