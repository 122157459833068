@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.subscription-upsell {
  @include container;
  padding: $spacing-unit * 2;
  margin: $spacing-unit * 2 0;
  background: linear-gradient(145deg, rgba($dominant-purple, 0.03), white);
  border-radius: 1rem;
  text-align: center;
  @include box-shadow(2);
  border: 1px solid rgba($light-purple, 0.1);

  .upsell-header {
    @include flex-center;
    gap: $spacing-unit-small;
    margin-bottom: $spacing-unit;

    .header-icon {
      color: $teal;
      font-size: 2rem;
      filter: drop-shadow(0 2px 4px rgba($teal, 0.2));
    }

    h3 {
      color: $dark-purple;
      font-family: $font-primary;
      margin: 0;
      @include responsive-font-size(1.5rem, 1.75rem, 2rem);
    }
  }

  .upsell-description {
    color: $charcoal;
    font-family: $font-paragraph;
    max-width: 600px;
    margin: 0 auto $spacing-unit * 1.5;
    line-height: 1.7;
    @include responsive-font-size(1rem, 1.05rem, 1.1rem);

    .inline-icon {
      color: $rich-gold;
      margin-right: $spacing-unit-small;
      vertical-align: middle;
    }
  }

  .upgrade-button {
    @include flex-center;
    gap: $spacing-unit-small;
    margin: 0 auto;
    padding: $spacing-unit $spacing-unit * 2;
    background: linear-gradient(45deg, $light-purple, $dominant-purple);
    color: white;
    border: none;
    border-radius: 2rem;
    font-family: $font-action;
    font-weight: 600;
    @include transition(all);
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(
        45deg,
        transparent 25%,
        rgba(white, 0.1) 50%,
        transparent 75%
      );
      transform: rotate(45deg);
      opacity: 0;
      @include transition(opacity);
    }

    &:hover {
      transform: translateY(-2px);
      @include box-shadow(3);

      &::after {
        opacity: 1;
      }

      .button-icon {
        transform: translateX(3px);
      }
    }

    .button-icon {
      @include transition(transform);
      font-size: 1.2rem;
    }
  }

  @include media-breakpoint-down(md) {
    padding: $spacing-unit;

    .upgrade-button {
      width: 100%;
      padding: $spacing-unit;
    }
  }
}
