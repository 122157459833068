/* 🔥 Product Details Carousel – FINAL REFINEMENTS */
/* ✅ Product Details Carousel – Styled with Ruoth’s Brand */
@import "../../styles/variables";
@import "../../styles/mixins";
@import "~bootstrap/scss/bootstrap";

.product-details-carousel {
  position: relative;
  width: 100%;
  // max-width: auto;
  margin: auto;
  padding: 20px;
  background: #ffffff; // ✅ Keeping the white background for a clean look
  border-radius: 12px;
  padding-bottom: 40px; // ✅ Adds more space at the bottom
  @include box-shadow(2);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.8) 100%);
    pointer-events: none;
    opacity: 0.5;
  }
  
  
  .instant-value img {
    width: 50%; // Default for most screens
    max-width: 300px; // Prevents it from being too large on wider screens
    display: block;
    margin: 0 auto; // Centers the image properly
    transition: transform 0.3s ease-in-out;

    &:hover {
    transform: scale(1.05);
    }

    @include respond-to("small") {
      width: 70%; // Slightly bigger for mobile
      max-width: 250px; // Adjusts max size on small screens
    }

    
  }
  .slick-slider {
    position: relative;
  }

  .slick-slider::after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: $dominant-purple;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.4s ease-in-out;
  }
  

  .slick-prev,
  .slick-next {
    font-size: 24px;
    color: $dominant-purple;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease, transform 0.2s ease;

    &:hover {
      opacity: 1;
      transform: translateY(-50%) scale(1.1); // Slight scale on hover
    }

    @include respond-to("small") {
      opacity: 0.3; // Lower opacity on mobile
    }
  }

  .slick-prev {
    left: -30px; // Adjusted for better placement
  }

  .slick-next {
    right: -30px;
  }


  .swipe-hint {
    font-size: 14px;
    color: $slate-gray;
    text-align: center;
    margin-top: 5px;
    animation: fadeInOut 2s infinite;
  }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0.6; }
    50% { opacity: 1; }
  }



  .carousel-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    transform: translateY(10px);
    transition: opacity 0.4s ease-out, transform 0.3s ease-out;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    .slide-title {
      color: $dominant-purple;
      font-family: $font-primary;
      font-weight: 700;
      @include responsive-font-size(1.3rem, 1.5rem, 1.6rem);
      margin-bottom: 12px;
    }

    /* ✅ Styled CKEditor Content */
    .slide-content {
      font-size: 16px;
      color: $dark-purple;
      font-family: $font-paragraph;
      line-height: 1.7;
      max-width: 90%;
      text-align: left;
      margin: auto;

      /* ✅ Proper spacing for paragraphs */
      p {
        margin-bottom: 12px;
      }

      /* ✅ Bullet points & ordered lists */
      ul, ol {
        margin: 10px 0;
        padding-left: 20px;

        li {
          margin-bottom: 6px;
        }
      }

      /* ✅ Nested lists styling */
      ul ul, ol ol {
        padding-left: 30px;
        margin: 5px 0;
      }

      /* ✅ Improve contrast for bold text */
      strong, b {
        color: $charcoal;
        font-weight: 700;
      }

      /* ✅ Elegant blockquotes */
      blockquote {
        border-left: 4px solid $rich-gold;
        padding-left: 15px;
        font-style: italic;
        color: $teal;
        margin: 12px 0;
      }

      /* ✅ Make images responsive */
      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        margin: 10px 0;
      }

      /* ✅ Image Captions */
      figure {
        margin: 15px 0;
        
        img {
          display: block;
          margin: auto;
        }

        figcaption {
          font-size: 14px;
          color: $slate-gray;
          text-align: center;
          margin-top: 5px;
        }
      }

      /* ✅ Responsive Tables */
      table {
        overflow-x: auto;
        display: block;
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;

        th, td {
          border: 1px solid $light-gray;
          padding: 8px;
          text-align: left;
        }

        th {
          background: $very-light-gray;
          font-weight: 600;
        }
      }
    }

    .instant-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; // ✅ Ensures vertical centering
      text-align: center;
      margin-bottom: 15px;
      max-width: 100%;
      padding: 10px 20px; // ✅ Adds padding for better spacing
    
      img {
        width: 50%;          // ✅ Prevents image from being too big
        max-width: 300px;    // ✅ Keeps image within bounds
        height: auto;
        border-radius: 8px;
        @include box-shadow(1);
      }
    
      p {
        font-weight: 600;
        font-size: 14px;
        color: $charcoal;
        margin-top: 8px;
        max-width: 90%; // ✅ Ensures text doesn’t stretch too wide
      }
    
      .swipe-hint {
        font-size: 14px;
        color: $slate-gray;
        margin-top: 5px;
        animation: fadeInOut 2s infinite;
      }
    }
    

    .unlock-btn {
      @include button-styles($dominant-purple, white);
      padding: 12px 18px;
      border-radius: 6px;
      cursor: pointer;
      transition: background 0.3s, box-shadow 0.3s ease-in-out;
    
      &:hover {
        background-color: darken($dominant-purple, 10%);
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
      }
    
      &:active {
        transform: scale(0.98);
      }
    }

    .unlock-btn:active {
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }
    
    

    .micro-reward {
      display: flex;
      align-items: center;
      justify-content: center; // ✅ Centers it properly
      font-size: 16px;
      font-weight: 500;
      color: $rich-gold;
      margin-top: 10px;
      transition: opacity 0.5s ease-in-out;
      opacity: 1;
      width: 100%; // ✅ Ensures it spans full width and aligns properly
      text-align: center; // ✅ Fixes floating issue
    }
    
  }

  .slick-next, .slick-prev {
    z-index: 10;
    font-size: 20px;
    color: $slate-gray;
    @include transition(color, 0.3s);
    
    &:hover {
      color: $charcoal;
    }
  }

  .slick-dots {
    bottom: -25px;

    li {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $light-gray;
        @include transition(background, 0.3s);
      }

      &.slick-active button {
        background: $dominant-purple;
      }
    }
  }
}
