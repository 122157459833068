/* src/index.css or src/styles/globals.css */
@import "~bootstrap/scss/bootstrap";
@import "./variables";
@import "./mixins";

// Import Component Styles
@import "components/navbar";
@import "components/footer";
@import "components/homepage";
@import "components/productCatalogue";
@import "components/productDetail";
@import "components/checkout";
@import "components/userDashboard";
@import "components/contentHub";
@import "components/customerSupport";

// Reset and Base Styles
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-secondary;
  color: $dark-purple;
  background-color: $very-light-gray;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  color: $dark-purple;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  font-family: $font-paragraph;
}

a {
  color: $light-purple;
  text-decoration: none;
  &:hover {
    color: $dominant-purple;
    text-decoration: underline;
  }
}

// Buttons
.button {
  background-color: $dominant-purple;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-family: $font-action;
  cursor: pointer;
  &:hover {
    background-color: darken($dominant-purple, 10%);
  }
}

// Inputs
input,
textarea {
  font-family: $font-forms;
  border: 1px solid $light-gray;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
  margin-bottom: 1rem;
}

input:focus,
textarea:focus {
  border-color: $light-purple;
  outline: none;
}

// Container
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

// Grid System
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.col {
  flex: 1;
  padding: 0.5rem;
}

@media (min-width: 600px) {
  .col-6 {
    flex: 0 0 50%;
  }
}

@media (min-width: 900px) {
  .col-4 {
    flex: 0 0 33.3333%;
  }
  .col-8 {
    flex: 0 0 66.6667%;
  }
}

// Utility Classes
.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

// Navbar Specific Adjustments
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .container {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
  }
}
