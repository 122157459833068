@import "~bootstrap/scss/bootstrap";
@import "../../styles/variables";
@import "../../styles/mixins";

.product-specifications {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;

  h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }

    .spec-key {
      font-weight: bold;
      text-transform: capitalize;
      width: 40%;
      color: #333;
    }

    .spec-value {
      color: #555;
    }
  }

  p {
    text-align: center;
    font-style: italic;
    color: #777;
  }
}
